import {
  NOTIFICATION_PERMISSIONS,
  BRANDLOGO_CLASS_BY_BRANDNAME,
  INVITE_FAILURE_MESSSAGE_MAP,
  IMAGE_PREVIEW_PATH,
  TEST_PROJECTS,
  INTERNAL_PROJECTS,
  TAKING_CHAT_STATUSES,TYPING_STATUS, MESSAGE_FROM, INTERACTION_TYPE, defaultCampaignData,
  NOT_TAKING_CHAT_STATUSES,
  OFFLINE_CHAT_STATUSES,
  CHAT_STATUS,
  MESSAGE_TYPE,
  campaignTypes,
  CHATSUPPORT_BRAND_ID,
  FREE_PLAN,
  PLAN_NAME_DETAILS,
  CURSOR_STATUS,
  ADVANCED_SEARCH,
  LOCAL_STORAGE_KEY,
  CUSTOMER_NAME_SEARCH,
  COMPANY_NAME_SEARCH,
  TAG_NAME_SEARCH,
  ERROR_MESSAGE,
  ABNORMAL_DISCONNECTION,
  CONVERSATION_LIST_CONTAINER_CLASSNAME,
  CHAT_FILTERS_CHAT_STATUS,
  CHAT_ANSWERING_POPUP_NOTIFICATION_SOUND,
  SETTINGS_COMPONENT_MODE,
  CONNECT_USER_AVATAR_COLOR,
  LIVE_APP_MODE_HOST,
  STAGING_APP_MODE_HOST,
  LIVE,
  STAGING,
  LOCAL,
  EMAIL_SUBSCRIPTION_FILTER_TYPE,
  filterTypes,
  EMAIL_SUBSCRIPTION_UUID,
  EMAIL_UNSUBSCRIPTION_UUID,
  AVAILABILITY_STATUS,
  PLAN_ID,
  CONNECT_AUTH,
  NEW_MESSAGE_NOTIFIER,
  MESSAGE_TYPE_LOWERCASE,
  LIVE_LOGOUT_URL,
  STAGING_LOGOUT_URL,
  CLIENT_LOGIN_REFERENCE,
  MILLIS,
  SINGULAR_TIME_INDICATORS,
  PLURAL_TIME_INDICATORS_SUFFIX,
  EMPTY_STRING,
  DATA_TYPES,
  DEFAULT_CAMPAIGN_CONTENT,
  READABLE_MESSAGE_TYPES,
} from "./Constants.js";
import { WILDCARD_PROPERTIES } from "../contacts/commons/ContactConstants.js";
import Clipboard from "clipboard";
import Base64Helper from "js-base64";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import color from "color";
import * as NotificationActions from "../actions/NotificationAction";
import * as MessageActions from "../actions/MessageAction";
import { conversationListRequest } from '../actions/ConversationInfoAction';
var ctzc = require("country-tz-currency");
const Crypto = require("crypto");
import { parsePhoneNumberFromString, isValidNumber } from "libphonenumber-js";
import { store } from "../app.js";
import { isUserHavingPermission } from "./RolesUtility";
import {
  sendMessage,
} from "../actions/MessageAction";
import { trackChatStatus, updateConversationRealTime } from "../actions/ConversationInfoAction.js";
import sha1 from 'sha1'
import ExternalBaseApis from "../aw_recents/ExternalBaseApis.js";
import { mailGlobalError } from "../actions/GlobalErrorAction.js";
import { updateLastNotifyMessageId } from "../actions/NotificationAction";
import emojiRegex from "emoji-regex";
import { getStaffContact } from "../components/myteam/Action/StaffContactAction.js";
import { showVoicebox } from "../actions/VoiceboxAction.js";
import cookie from "react-cookie";

const TRUNCATED_STRING_POSTFIX = "...";
const AUDIO_OBJECT = window.Audio
  ? new Audio(NEW_MESSAGE_NOTIFIER.TONEPATH)
  : undefined;

export const isValidPhotoUrl = url => {
  return (url && !url.includes("null"));  
}

export const getPresenceClassName = (staff) => {
  let { takingChatStatus, isOnline } = staff;

  if (takingChatStatus) {
    if (OFFLINE_CHAT_STATUSES.includes(takingChatStatus)) return "offline";
    else if (NOT_TAKING_CHAT_STATUSES.includes(takingChatStatus))
      return "busy";
    else return "available";
  } else {
    return isOnline ? "available" : "offline";
  }
}

export const isHipaaCompliant = (projectList=[], projectkey) => {
    let firstProject = projectList.filter(
          (project) => project.key == "LS-" + projectkey
        )[0];
    return firstProject && (firstProject.isHippaEnabled || firstProject.hippaEnabled);
}  

export const getMentionSuggestions = (
  staffMap={},
  user,
  alreadyMentionedStaffs,
  overflowAgent={},
) => {
  let mentions = [];
  let userId = "";
  let overflowAgentId = "";

  // let defaultImagePath = "https://staging.chatsupport.co/images/user-white-icon.svg";
  let defaultImagePath = "no-image";

  if (!!user && user.data && user.data.id) userId = user.data.id;

  if (!!overflowAgent && overflowAgent.key) overflowAgentId = overflowAgent.key;

  console.info("overflowAgentId:", overflowAgentId);
  console.info("User Id :", userId);
  

  Object.keys(staffMap).map((key) => {
    let staff = staffMap[key];
    let status = getPresenceClassName(staff);
    if (staff.status == "ACTIVE") {
      if (
        userId == staff.key ||
        overflowAgentId == staff.key ||
        (alreadyMentionedStaffs && alreadyMentionedStaffs.includes(staff.key))
      )
        return;

      mentions.push({
        name: `${staff.fullName}`,
        avatar: `${
          isValidPhotoUrl(staff.photoUrl)
            ? staff.photoUrl
            : defaultImagePath
        }`,
        status : `${status}`,
        color: `${staff.avatarColor}`
      });
    }
  });
  return mentions;
};

export const getNotesContent = (isConnectedToNetwork) => {
  let notesText = "Add your note and type @ to notify a team member.";
  if (isOverflowConnection()) notesText = "Add your note.";
  if (
    !isOverflowConnection() ||
    (isOverflowConnection() && isConnectedToNetwork)
  )
    notesText += " Press Esc key to exit notes.";
  return notesText;
};

export const getPlaceHolderText = (
  isEmailConversation,
  isNotesActive,
  chatStatus,
  isReopened,
  isConnectedToNetwork,
  visitorEmailId
) => {
  let placeholderText = "";
  if (isEmailConversation && !isNotesActive) {
    placeholderText = `Reply as email. Message will be sent as an email to ${visitorEmailId}`;
  } else if (isNotesActive) {
    placeholderText = getNotesContent(isConnectedToNetwork);
  } else {
    if (chatStatus === "CLOSED" && !isReopened)
      placeholderText = "Send Message and Reopen chat";
    else placeholderText = "Type your message";
  }
  if (isOverflowAbnormalDisConnection())
    placeholderText = ABNORMAL_DISCONNECTION.PLACE_HOLDER;
  return placeholderText;
};

export const isOverflowAbnormalDisConnection = () => {
  try {
    if (isOverflowConnection()) {
      let conversationId = getQueryParam("conversation_id");
      let state = store.getState();
      let messageMap = state.MessageReducer.messageMap[conversationId];
      let message = {};
      for (let key in messageMap) {
        if (messageMap[key] && messageMap[key].type !== MESSAGE_TYPE.notes) {
          message = messageMap[key];
          break;
        }
      }
      if (
        message.type === MESSAGE_TYPE.overflow_chat_disconnected_abnormally &&
        message.messageFrom === MESSAGE_FROM.AGENT
      ) {
        return true;
      }
    }
  } catch (exception) {
    console.log(
      "exception occured in isOverflowAbnormalDisConnection " + exception
    );
  }
  return false;
};

export const getEncrypted = (plainText) => {
  return Base64Helper.Base64.encodeURI(plainText);
};

export const getDecrypted = (plainText) => {
  return Base64Helper.Base64.decode(plainText);
};

export const getAppMode = () => {
  let host = document.location.host;

  if(getIsSettingsComponent()){
    return getModeForSettingsComponent();
  }

  if(getIsAWRecentComponent()){
    return getModeForAWRecentComponent();
  }

  if (
    host.endsWith("livesupport-app.appspot.com") ||
    host.endsWith("livesupport-app.uc.r.appspot.com") ||
    LIVE_APP_MODE_HOST.includes(host)
  ) {
    return LIVE;
  } else if (
    host.endsWith("staging-live-support.appspot.com") ||
    host.endsWith("staging-live-support.uc.r.appspot.com") ||
    STAGING_APP_MODE_HOST.includes(host)
  ) {
    return STAGING;
  }
  return LOCAL;
};

export const getConnectUrl = () => {
  let host = document.location.host;
  if (
    host.endsWith("livesupport-app.appspot.com") ||
    host == "chatsupport.co" ||
    host == "app.chatsupport.co" ||
    host == "anywherehelp.io" ||
    host == "app.anywherehelp.io" ||
    host == "beta.chatsupport.co"
  ) {
    return "https://teleport.video";
  } else if (host.endsWith("staging-live-support.appspot.com")) {
    return "https://staging.teleport.video";
  }
  return "https://staging.teleport.video";
};

export const getHostName = () => {
  let host = document.location.host;
  if (
    host.endsWith("livesupport-app.appspot.com") ||
    host == "chatsupport.co" ||
    host == "app.chatsupport.co" ||
    host == "anywherehelp.io" ||
    host == "app.anywherehelp.io" ||
    host == "beta.chatsupport.co"
  ) {
    return "livesupport-app.appspot.com";
  } else if (host.endsWith("staging-live-support.appspot.com")) {
    return "staging-live-support.appspot.com";
  } else if (host.endsWith("localhost:8888")) {
    return "localhost:8888";
  }
  return "staging-live-support.appspot.com";
};

export const getImagePreviewUrl = (filePath) => {
  let mode = getAppMode();
  let imagePreviewUrl = "";

  imagePreviewUrl =
    mode == "live"
      ? "https://livesupport-app.appspot.com"
      : "https://staging-live-support.appspot.com";
  imagePreviewUrl = imagePreviewUrl + IMAGE_PREVIEW_PATH;

  imagePreviewUrl = imagePreviewUrl.replace("{fileName}", filePath);

  return imagePreviewUrl;
};

export const getLocation = (href) => {
  var match = href.match(
    /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/
  );
  return (
    match && {
      href: href,
      protocol: match[1],
      host: match[2],
      hostname: match[3],
      port: match[4],
      pathname: match[5],
      search: match[6],
      hash: match[7],
    }
  );
};

export const getOrigin = () => {
  return document.location.origin;
};

export const iswebapp = () => {
  let origin = document.location.origin;
  if (
    origin == "https://staging.chatsupport.co" ||
    origin == "https://app.chatsupport.co" ||
    origin == "https://app.anywherehelp.io" ||
    origin == "https://staging.anywherehelp.io" ||
    origin == "https://beta.chatsupport.co" ||
    origin == "https://chatsupport.answerforce.app" 
  )
    return true;
  else return false;
};

export const getBucketHostName = () => {
  return "https://storage.googleapis.com";
};

export const deepClone = (object) => {
  let deepClonedObject = JSON.parse(JSON.stringify(object));
  return deepClonedObject;
};

export const isValidName = (name) => {
  return /^[a-z ,.'-]+$/i.test(name);
};

export const isValidPersonName = (name) => {
  return /^\s*([A-Za-z]{1,}([\.,] |[-']| ))+[A-Za-z]+\.?\s*$|\w+/.test(name);
};

export const isValidString = (stringParam) => {
  if (
    stringParam == "" ||
    stringParam == "null" ||
    stringParam == null ||
    stringParam == undefined ||
    !stringParam.trim()
  )
    return false;
  else return true;
};

export const isValidURL = (url) => {
  var RegExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  if (RegExp.test(url)) return true;
  else return false;
};

export const isURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return pattern.test(str);
};

export const isValidEmail = (email) => {
  var RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return RegExp.test(email);
};
export const isValidProjectId = (projectId) => {
  if (
    projectId &&
    projectId != "null" &&
    projectId != "undefined" &&
    projectId.startsWith("LS-") &&
    projectId.length == 11
  )
    return true;
  return false;
};
export const isValidPassword = (testString) => {
  return testString && testString.length >= 6;
};

const getTimeIndicatorsForMinutes = (timeInMillis) => {
  if (timeInMillis <= MILLIS.two_minutes) {
      return timeInMillis < MILLIS.minute ? SINGULAR_TIME_INDICATORS.just_now : SINGULAR_TIME_INDICATORS.a_minute_ago;
  } else {
      return Math.floor(timeInMillis / MILLIS.minute) + PLURAL_TIME_INDICATORS_SUFFIX.minutes_ago;
  }
};

const getTimeIndicatorsForHours = (timeInMillis) => {
  return timeInMillis <= MILLIS.two_hours ? SINGULAR_TIME_INDICATORS.an_hour_ago : 
      Math.floor(timeInMillis / MILLIS.hour) + PLURAL_TIME_INDICATORS_SUFFIX.hours_ago;
};

const getTimeIndicatorsForDays = (timeInMillis) => {
  return timeInMillis <= MILLIS.two_days ? SINGULAR_TIME_INDICATORS.yesterday : 
      Math.floor(timeInMillis / MILLIS.day) + PLURAL_TIME_INDICATORS_SUFFIX.days_ago;
};

const getTimeIndicatorsForMonths = (timeInMillis) => {
  return timeInMillis <= MILLIS.two_months ? SINGULAR_TIME_INDICATORS.a_month_ago : 
      Math.floor(timeInMillis / MILLIS.month) + PLURAL_TIME_INDICATORS_SUFFIX.months_ago;
};

const getTimeIndicatorsForYears = (timeInMillis) => {
  return timeInMillis <= MILLIS.two_years ? SINGULAR_TIME_INDICATORS.one_year_ago : 
      Math.floor(timeInMillis / MILLIS.year) + PLURAL_TIME_INDICATORS_SUFFIX.years_ago;
};

export const getTimeIndicators = (timeInMillis) => {
  if (typeof timeInMillis != DATA_TYPES.number) {
    return EMPTY_STRING;
  } else if (timeInMillis < MILLIS.hour) {
      return getTimeIndicatorsForMinutes(timeInMillis);
  } else if (timeInMillis < MILLIS.day) {
      return getTimeIndicatorsForHours(timeInMillis);
  } else if (timeInMillis < MILLIS.month) {
      return getTimeIndicatorsForDays(timeInMillis);
  } else if (timeInMillis < MILLIS.year) {
      return getTimeIndicatorsForMonths(timeInMillis);
  } else {
      return getTimeIndicatorsForYears(timeInMillis);
  }
};

export const getTimeout = (timeInMillis, isTimerInitiated) => {
  if (timeInMillis <= MILLIS.minute) {
    return isTimerInitiated
      ? MILLIS.minute / 10
      : MILLIS.minute - (timeInMillis % MILLIS.minute);
  } else if (timeInMillis <= MILLIS.hour) {
    return isTimerInitiated
      ? MILLIS.hour
      : MILLIS.hour - (timeInMillis % MILLIS.hour);
  } else if (timeInMillis <= MILLIS.day) {
    return isTimerInitiated
      ? MILLIS.day
      : MILLIS.day - (timeInMillis % MILLIS.day);
  } else if (timeInMillis <= MILLIS.month) {
    return isTimerInitiated
      ? MILLIS.month
      : MILLIS.month - (timeInMillis % MILLIS.month);
  } else {
    return isTimerInitiated
      ? MILLIS.year
      : MILLIS.year - (timeInMillis % MILLIS.year);
  }
};

export const getDisplayTime = (timeStamp, isTimerInitiated) => {
  const currentTime = getServerTime();
  const contactedTime = new Date(timeStamp);
  if (!timeStamp || contactedTime > currentTime) {
    return { displayTime: SINGULAR_TIME_INDICATORS.just_now, timeout: 1000 };
  }

  const timeDiff = currentTime - contactedTime;
  const displayTime = getTimeIndicators(timeDiff);
  const timeout = getTimeout(timeDiff, isTimerInitiated);

  return { displayTime, timeout };
};

export const isValidPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return false;
  return (
    phoneNumber
      .toLowerCase()
      .split("ext.")[0]
      .trim()
      .match(/^[0-9-+(). ]*$/)
      .join("").length >= 10 &&
    /^([0-9-+() \.]{1})+(ext\.[ ]*[0-9]+)*$/.test(phoneNumber)
  );
};

export const getDisplayDate = (createdDate) => {
  if (getDaysDifference(createdDate) < 1) return "Today";
  else if (getDaysDifference(createdDate) < 2) return "Yesterday";
  else if (getDaysDifference(createdDate) >= 2)
    return getNewFormatedTime(createdDate);
}

export const getFormattedNumber = (number, countryCode) => {
  if (!number) return "";
  let phoneNumber = number.includes("+") ? number : `+${number}`;
  let formattedNumber = parsePhoneNumberFromString(phoneNumber);
  if (!formattedNumber || !isValidNumber(formattedNumber.number)) {
    phoneNumber = getCountryCode(countryCode) + "" + number;
    let newFormattedNumber = parsePhoneNumberFromString(phoneNumber);
    if (newFormattedNumber) return newFormattedNumber.formatInternational();
  }
  if (formattedNumber) return formattedNumber.formatInternational();
  return formattedNumber || "";
};

export const getUnformattedPhoneNumber = (number) => {
  let phoneNumber = parsePhoneNumberFromString(number);
  phoneNumber = phoneNumber.number;
  return phoneNumber.replace("+", "");
};
export const isValidProjectName = (testString) => {
  return /^[a-zA-Z0-9 _]*$/im.test(testString);
};

export const isAlphaNumeric = (testString) => {
  var alphaNumericRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))"
  );
  return alphaNumericRegex.test(testString);
};

export const containsEmoji = (word) => {
  return emojiRegex().test(word);
}

export const userNameRules = (word)=>{
  const pattern = /[0-9`!@#$%^&*()_+=\]\[{};:"\\|,<>\/?~]/;
  return word.match(pattern);
}

export const splitFullName = (userName) => {
  const fullname = userName.split(" ");
  const firstName = fullname[0];
  const lastName = fullname.slice(1, fullname.length).join(" ");
  return {firstName, lastName};
}

export const containsAlphabet = (word) => {
  return word.match(/[a-zA-Z]/g);
};

export const containsNumbers = (word) => {
  return word.match(/\d/g);
};

export const isStringBeginOrEndWithSpace = (word) => {
  return word && (word[0] == " " || word[word.length - 1] == " ");
};

export const getErrorMessageStyle = (error) => {
  return `inputGroup-new ${ error ? "inputError" : ""}`;
}
export const isEnterKeyPressed = (event) => {
  return event.key == "Enter" || event.keyCode === 13;
}
export const getProjectId = (projectKey) => {
  return "LS-" + projectKey;
};

export const getProjectKey = (projectId) => {
  return projectId.replace("LS-", "");
};

export const incrementConversationCount = (
  staffsMap,
  userId,
  mentionedStaffs
) => {
  if (userId && staffsMap[userId]) {
    staffsMap[userId].openConversationCount = getIncrementedCount(
      staffsMap[userId].openConversationCount
    );
  }
  if (mentionedStaffs && !isNonEmptyArray(mentionedStaffs)) {
    for (let mentionedStaff of mentionedStaffs) {
      if (staffsMap[mentionedStaff])
        staffsMap[
          mentionedStaff
        ].mentionedConversationCount = getIncrementedCount(
          staffsMap[mentionedStaff].mentionedConversationCount
        );
    }
  }
};

export const decrementConversationCount = (
  staffsMap,
  userId,
  mentionedStaffs
) => {
  if (userId && staffsMap[userId]) {
    staffsMap[userId].openConversationCount = getDecrementedCount(
      staffsMap[userId].openConversationCount
    );
  }
  if (mentionedStaffs && !isNonEmptyArray(mentionedStaffs)) {
    for (let mentionedStaff of mentionedStaffs) {
      if (staffsMap[mentionedStaff])
        staffsMap[
          mentionedStaff
        ].mentionedConversationCount = getDecrementedCount(
          staffsMap[mentionedStaff].mentionedConversationCount
        );
    }
  }
};

export const incrementUnassignedCount = (project) => {
  if (project) {
    project.unAssignedConversationCount = getIncrementedCount(
      project.unAssignedConversationCount
    );
  }
};

export const decrementUnassignedCount = (project) => {
  if (project) {
    project.unAssignedConversationCount = getDecrementedCount(
      project.unAssignedConversationCount
    );
  }
};

export const getIncrementedCount = (count) => {
  return !isNaN(count) ? count + 1 : 1;
};

export const getDecrementedCount = (count) => {
  return !isNaN(count) && count > 0 ? count - 1 : 0;
};

export const isNonEmptyArray = (testArray) => {
  return Array.isArray(testArray) && testArray.length > 0;
};

export const getArrayDifference = (array1, array2) => {
  //return elements present only in first array
  return array1.filter((x) => !array2.includes(x));
};

export const isValidChatPromptURL = (url) => {
  try {
    let pattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
    return pattern.test(url);
  } catch (e) {
    return false;
  }
};

//have to move the following methods some other to file
export const generateRandomPromptId = () => {
  let d = new Date().getTime();
  let uuid = "xxxxxxxxx".replace(/[x]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export const deleteProjectFromArray = (projectArray, response) => {
  for (let index in projectArray) {
    if (projectArray[index].key === response.deletedKey) {
      projectArray.splice(index, 1);
    }
  }
  return projectArray;
};

export const updatePromptsFromResponse = (promptsArray, response) => {
  for (let index in promptsArray) {
    if (promptsArray[index].key === response.key) {
      promptsArray[index] = response;
      break;
    }
  }
  return promptsArray;
};

export const formatDate = (date) => {
  return getFormattedDate(date, "MMM DD, hh:mm A");
};

export const formatDateWithYearAndSeconds = (date) => {
  return getFormattedDate(date, "DD MMM YYYY hh:mm:ss A");
};

export const getFormattedDate = (date, format) => {
  const hours = date.getHours();
  if (hours % 12 < 10 && format.includes("hh"))
    format = format.replace("h", "");
  return moment(date).format(format);
};

export const formatDateWithShortMonth = (date) => {
  return getFormattedDate(date, "MMM DD, hh:mm A");
};

export const getMonths = () => {
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return monthNames;
};

export const getDropdownPayload = (
  options,
  optionKeyField,
  optionImgField,
  optionTextFields,
  optionPresense,
  openConversationCount,
  avatarColor,
  isTakingChat
) => {
  let dropDownPayload = [];

  let defaultImagePath = "https://app.chatsupport.co/images/Staff-Avatar.svg";

  if (options) {
    dropDownPayload = options.map((option) => {
      return {
        key: option[optionKeyField],

        img:
          option[optionImgField] && option[optionImgField] != "null"
            ? option[optionImgField]
            : defaultImagePath,

        text: optionTextFields
          .map((textField) => (option[textField] ? option[textField] : ""))
          .reduce((acc, val) => acc + " " + val),

        openConversationCount:
          option[openConversationCount] != undefined &&
          option[openConversationCount] != "null"
            ? option[openConversationCount]
            : 0,

        optionPresense: option[optionPresense],
        avatarColor : option[avatarColor],
        isTakingChat : option[isTakingChat]
      };
    });
  }

  return dropDownPayload;
};

export const getMentionedStaffs = (
  alreadyMentionedStaffs,
  currentlyMentionedStaffs
) => {
  let mentionedStaffs = [];
  alreadyMentionedStaffs = alreadyMentionedStaffs ? alreadyMentionedStaffs : [];

  mentionedStaffs = mergeAndDeduplicateArrays([
    alreadyMentionedStaffs,
    currentlyMentionedStaffs,
  ]);

  console.info("All mentioned Staffs:", mentionedStaffs);

  return mentionedStaffs;
};

export const extractMentionedStaffs = (message) => {
  var regex = /\<\|(.*?)\|\>/g;
  let match;
  let agentIds = [];

  do {
    match = regex.exec(message);
    if (match) {
      agentIds.push(match[1]);
    }
  } while (match);

  return agentIds;
};

export const mergeAndDeduplicateArrays = (arrayOfArrays) => {
  //merge all arrays and remove duplicates
  return [...new Set([].concat(...arrayOfArrays))];
};

export const generateMessageId = () => {
  let d = new Date().getTime();
  let messageId = "xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );

  return messageId;
};

export const generateReferenceId = () => {
  let d = new Date().getTime();
  let messageId = "xxxx-xxxx".replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });

  return messageId;
};

export const spawnDesktopNotification = (
  notificationTitle,
  notificationOptions,
  onClickHandler,
  dismissAfter,
  notificationTonePath,
  messageKey,
  projectId
) => {
  if (!("Notification" in window)) {
    console.warn("This browser does not support desktop notification!");
  } else {
    let { isActiveOnTab } = store.getState().UserReducer;
    if (Notification.permission !== NOTIFICATION_PERMISSIONS.GRANTED) {
      Notification.requestPermission().then(() => {
        if (Notification.permission == NOTIFICATION_PERMISSIONS.GRANTED) {
          if (!isActiveOnTab)
            notificationOptions = Object.assign({}, notificationOptions, {
              requireInteraction: true,
            });

          notificationOptions = Object.assign({}, notificationOptions, {
            tag: messageKey,
          });
          var notification = new Notification(
            notificationTitle,
            notificationOptions
          );

          if (notificationTonePath) new Audio(notificationTonePath).play();

          if (onClickHandler)
            notification.onclick = (() => {
              window.focus();
              notification.close();
              onClickHandler();
            }).bind(this);

          if (isActiveOnTab && dismissAfter) {
            setTimeout(
              (() => {
                notification.close();
              }).bind(notification),
              dismissAfter
            );
          }

          storeNotificationInLocalStorage(notification, projectId);
        }
      });
    } else {
      if (!isActiveOnTab)
        notificationOptions = Object.assign({}, notificationOptions, {
          requireInteraction: true,
        });

      notificationOptions = Object.assign({}, notificationOptions, {
        tag: messageKey,
      });

      var notification = new Notification(
        notificationTitle,
        notificationOptions
      );

      if (notificationTonePath) new Audio(notificationTonePath).play();

      if (onClickHandler)
        notification.onclick = (() => {
          window.focus();
          notification.close();
          onClickHandler();
        }).bind(this);

      if (isActiveOnTab && dismissAfter) {
        setTimeout(
          (() => {
            notification.close();
          }).bind(notification),
          dismissAfter
        );
      }
      storeNotificationInLocalStorage(notification, projectId);
    }
  }
};

export const getFailedInvitesPopupProps = (failedInvites, error) => {
  let popupContent = "";
  let popupProps = "";
  let failureMessage = "";

  if (failedInvites && failedInvites.length > 0) {
    for (var index in failedInvites) {
      failureMessage = INVITE_FAILURE_MESSSAGE_MAP[failedInvites[index].msg]
        ? INVITE_FAILURE_MESSSAGE_MAP[failedInvites[index].msg]
        : failedInvites[index].msg;
      popupContent =
        popupContent +
        "<span>" +
        failedInvites[index].email +
        " - " +
        failureMessage +
        "</span>";
    }

    popupProps = {
      okButton: { text: "Ok" },
      cancelButton: { text: "cancel", display: false },
      popupHeader: "Below emails weren't invited!",
      popupContent: popupContent,
      closeOnOkButton: true,
    };
  }

  return popupProps;
};

export const getFailedInvitesPopupProps2 = (failedInvites, error) => {
  let failedInviteEmailIds = "";
  let popupContent = "";
  let popupProps = "";

  if (error && error == "AW Invitations awaits response")
    popupContent =
      "<p>The following email(s) were not invited, as they hadn't respond to the previous invitation!</p>";
  else popupContent = "<p>The following email(s) were not invited !</p>";

  if (failedInvites && failedInvites.length > 0) {
    for (var index in failedInvites) {
      failedInviteEmailIds =
        failedInviteEmailIds + "<p>" + failedInvites[index] + "</p>";
    }

    popupProps = {
      okButton: { text: "Ok" },
      cancelButton: { text: "cancel", display: false },
      popupHeader: "Oops! Something went wrong",
      popupContent: popupContent + failedInviteEmailIds,
    };
  }

  return popupProps;
};

export const getApiKey = (mode) => {
  if ("live" === mode) {
    return "03c014d8-9a5c-c9de-eb74-dbf34ee06f5d";
  } else if ("staging" === mode) {
    return "ef9c8274-5b51-9def-fec6-a30964935f43";
  }
  return "";
};
export const getOverflowApiKey = (mode) => {
  if ("live" === mode) {
    return "29d9e431-ad5b-99b9-d080-b9c90ea183ef";
  } else if ("staging" === mode) {
    return "fad8ed73-5c71-88bc-8b65-471e04f9e3df";
  }
  return "";
};
export const getOverflowRTMBaseURL = (mode) => {
  const URLS = {
    live: "https://api.production-rtm.anywhereworks.com",
    staging: "https://api.staging-rtm.anywhereworks.com",
  };

  return URLS[mode] || "";
};

export const getAwPrefixUserId = (userId) => {
  return "aw/" + userId;
}

export const getAwFormattedUserIds = (userId) => {
  let formattedIds = [];
  userId.forEach(function (item, index){
    formattedIds.push("aw/" + item);
  })
  return formattedIds;
}

export const getAvailabilityMessage = (isTakingChat, userId, widgetId) => {
  let availableMessageType = isTakingChat
    ? "AVAILABILITY_SUBSCRIPTION"
    : "AVAILABILITY_UNSUBSCRIPTION";
  let userIdWithPrefix =  (getIsAWRecentComponent() && userId.indexOf('aw/') === -1) ? getAwPrefixUserId(userId) : userId;
  return {
    type: availableMessageType,
    userId : userIdWithPrefix,
    widgetId,
    channel: `private/visitor/${widgetId}/routing`,
    messageId: createUUId(),
    isFromAWIntegration: getIsAWRecentComponent()
  };
};

export const hasOverflowEnabledProjects = (projects) => {
  for (const project of Object.values(projects)) {
    if (project.isOverFlowEnabled) return true;
  }
  return false;
};

export const getPresenceMessage = (
  pulledStatus,
  projectId,
  takingChatStatus
) => {
  let isTakingChat = TAKING_CHAT_STATUSES.includes(takingChatStatus)
    ? true
    : false;
  let shouldRouteChats = TAKING_CHAT_STATUSES.includes(takingChatStatus)
  let channelByMode = getAwCsPresenceChannel(projectId);
  return {
    type: "USER_PRESCENCE_UPDATE",
    channel: channelByMode,
    ...pulledStatus,
    isTakingChat,
    takingChatStatus,
    shouldRouteChats
  };
};

export const getAwCsPresenceChannel = (projectId) => {
  if (getIsAWRecentComponent())
    return "presence/aw_chatsupport/" + projectId;
  else
    return "presence/agent/" + projectId;
}

export const getAPIBaseUrl = (mode) => {
  let appMode = getAppMode();
  if ("live" === appMode) {
    return "https://api.chatsupport.co/api";
  } else if ("staging" === appMode) {
    return "https://staging.api.chatsupport.co/api";
  }
  return "https://staging.api.chatsupport.co/api";
};

export const getWebappBaseUrl = () => {
  let appMode = getAppMode();
  if ("live" === appMode) {
    return "https://app.chatsupport.co/";
  } else if ("staging" === appMode) {
    return "https://staging.chatsupport.co/";
  }
  return "https://staging.chatsupport.co/";
};

export const getPaymentUrl = () => {
  let appMode = getAppMode();
  if ("live" === appMode) {
    return "https://payment.chatsupport.co";
  } else if ("staging" === appMode) {
    return "https://payment.staging.chatsupport.co";
  }
  return "https://payment.staging.chatsupport.co";
};

export const getDefaultConversationObject = (
  conversationId,
  projectId,
  visitorId,
  staffId
) => {
  let currentTime = new Date();
  return {
    key: conversationId,
    createdDate: currentTime.getTime(),
    modifiedDate: currentTime.getTime(),
    status: "ACTIVE",
    projectId: projectId,
    lastMessageId: null,
    visitorId: visitorId,
    chatStatus: "OPEN",
    readStatus: "READ",
    assignedTo: staffId,
    promptId: "",
    isConversationStarted: true,
    conversationType: "CHAT",
    tags: [],
    overflowStatus: "NOT_OVERFLOWED",
  };
};
export const createConversationId = () => {
  var crypto = window.crypto || window.msCrypto;
  let key = ([1e7] + 1e3 + 4e3).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
  return key;
};
export const isOverflowConnection = () => {
  let pathname = document.location.pathname;
  if (pathname.includes("overflow")) return true;
  return false;
};
export const isMineActiveChatPage = () => {
  let pathname = document.location.pathname;
  return pathname.includes("D/O/N") && !pathname.includes("All");
}
export const isGuestPage = () => location.pathname.includes("guests");
export const isQueuePage = () => location.pathname.includes("queue");
export const isContactDetail = () => {
  let pathname = document.location.pathname;
  if (pathname.includes("detail/history")) return true;
  return false;
};

export const isAwConnection = () => {
  let pathname = document.location.pathname;
  if (pathname.includes("anywhereworks")) return true;
  return false;
};

export const getVisitorFirstMessageTime = (conversation, message) => {
  let { visitorFirstMessageTime } = conversation;
  let { type, messageFrom, timestamp } = message;

  if (!visitorFirstMessageTime && type == "CHAT" && messageFrom == "VISITOR")
    visitorFirstMessageTime = timestamp;

  return visitorFirstMessageTime || 0;
};

export const getFirstReplyTime = (conversation, message) => {
  let { firstReplyTime, visitorFirstMessageTime } = conversation;
  let { type, messageFrom, timestamp } = message;

  if (
    !firstReplyTime &&
    visitorFirstMessageTime &&
    type == "CHAT" &&
    messageFrom == "AGENT" &&
    timestamp > visitorFirstMessageTime
  ) {
    firstReplyTime = Math.floor((timestamp - visitorFirstMessageTime) / 1000);
  }

  return firstReplyTime || 0;
};

export const getLastClosedTime = (conversation, message) => {
  let { lastClosedTime, visitorFirstMessageTime } = conversation;
  let { type, messageFrom, timestamp } = message;

  if (
    !lastClosedTime &&
    visitorFirstMessageTime &&
    timestamp > visitorFirstMessageTime
  ) {
  }
  lastClosedTime = Math.floor((timestamp - visitorFirstMessageTime) / 1000);

  return lastClosedTime || 0;
};

export const getParticipants = (conversation, message) => {
  let { participants } = conversation;
  let { type, messageFrom, senderId } = message;

  participants = Array.isArray(participants) ? participants : [];

  if (messageFrom == "AGENT" && !participants.includes(senderId))
    participants.push(senderId);

  return participants;
};

export const getQueryParam = (variableName) => {
  let querySubstring = window.location.search.substr(1).split("&");
  if (querySubstring == "") return "";
  var queryVariables = {};
  for (var i = 0; i < querySubstring.length; ++i) {
    var p = querySubstring[i].split("=", 2);
    if (p.length == 1) queryVariables[p[0]] = "";
    else queryVariables[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  }
  return queryVariables[variableName];
};
export const initiateClipboard = (
  selector,
  successFunction,
  errorFunction,
  config
) => {
  let clipboard = new Clipboard(selector, config);
  clipboard.on("success", function (e) {
    successFunction();
    e.clearSelection();
  });

  clipboard.on("error", function (e) {
    errorFunction();
  });
  return clipboard;
};

export const getUnsubscriptionData = (
  channel,
  pattern,
  subscriptionType,
  userIds
) => {
  return {
    channel: {
      [channel]: {
        pattern: pattern,
        subscriptionType: subscriptionType,
      },
    },
    userIds: userIds,
  };
};

export const createQueryByReference = (references, filterType, projectKey) => {
  let query = {
    projectId: "LS-" + projectKey,
    filter: {},
    not_filter: {},
    match: {},
    not_match: {},
    multi_match: {},
    multi_prop_or_match: [],
    not_multi_match: {},
    internal_or: [],
    internal_or_exists: {field : []},
    exists: { field: [] },
    not_exist: { field: [] },
    sort: { lastSeenTime: "desc" },
    range: [],
    size: 30,
    aggr: {},
    scroll: "6h",
    filterType: filterType,
  };
  let hasCompanyReference = false;
  for (let key in references) {
    let reference = references[key];
    let property = reference.property;
    property = reference.c
      ? property + getPropertyTypeToQuery(property, reference)
      : property;
    let dateToQuery;

    if (reference.type == "event")
      reference.value = reference.value ? reference.value : 0;

    console.log("this is reference filter type: ", reference.filterType);
    switch (reference.filterType) {
      case "contains": {
        if (WILDCARD_PROPERTIES.includes(property)) {
          property = property.replace("_keyword", "");
          query.multi_match[reference.value] = [
            property + "_keyword",
            property + "_standard",
            property + "_whitespace",
          ];
        } else if (property.startsWith("CF-")) {
          query.multi_match[reference.value] = [
            `${property}`,
            `${property}_keyword`,
            `${property}_standard`,
            `${property}_whitespace`,
          ];
        } else if (property.includes("fullName")) {
          let words = reference.value.trim().split(" ");
          let wordsCount = words.length;
          if (wordsCount <= 1) {
            query.multi_match[reference.value] = [
              `firstName`,
              `lastName`,
              `fullName`,
            ];
          } else {
            let lastName = words.pop();
            let firstName = words.join();
            query.multi_match[firstName] = [`firstName`];
            query.multi_match[lastName] = [`lastName`];
          }
        } else
          query.match[property] = query.match[property]
            ? [...query.match[property], reference.value]
            : [reference.value];
        break;
      }
      case "not_contains": {
        if (WILDCARD_PROPERTIES.includes(property)) {
          property = property.replace("_keyword", "");
          query.not_multi_match[reference.value] = [
            property + "_keyword",
            property + "_standard",
            property + "_whitespace",
          ];
        } else if (property.startsWith("CF-")) {
          query.not_multi_match[reference.value] = [
            `${property}`,
            `${property}_keyword`,
            `${property}_standard`,
            `${property}_whitespace`,
          ];
        } else if (property.includes("fullName")) {
          let words = reference.value.trim().split(" ");
          let wordsCount = words.length;
          if (wordsCount <= 1) {
            query.not_multi_match[reference.value] = [`firstName`, `lastName`];
          } else {
            let lastName = words.pop();
            let firstName = words.join();
            query.not_multi_match[firstName] = [`firstName`];
            query.not_multi_match[lastName] = [`lastName`];
          }
        } else
          query.not_match[property] = query.not_match[property]
            ? [...query.not_match[property], reference.value]
            : [reference.value];
        break;
      }
      case "exactly":
        if (WILDCARD_PROPERTIES.includes(property)) {
          query.filter[property] = query.filter[property]
            ? [...query.filter[property], reference.value]
            : [reference.value];
        } else if (property.startsWith("CF-")) {
          query.multi_match[reference.value] = [
            `${property}.keyword`,
            `${property}_keyword`,
            `${property}_standard.keyword`,
            `${property}_whitespace.keyword`,
          ];
        } else if (property.includes("fullName")) {
          let words = reference.value.trim().split(" ");
          let wordsCount = words.length;
          if (wordsCount <= 1) {
            query.multi_match[words.pop()] = [`firstName.keyword`];
            query.not_exist["field"].push(`lastName`);
          } else {
            let lastName = words.pop();
            let firstName = words.join();
            query.multi_match[firstName] = [`firstName.keyword`];
            query.multi_match[lastName] = [`lastName.keyword`];
          }
        } else {
          query.filter[property] = query.filter[property]
            ? [...query.filter[property], reference.value]
            : [reference.value];
        }
        break;
      case "is_bool_true":
        query.filter[property] = query.filter[property]
          ? [...query.filter[property], "true"]
          : ["true"];
        break;
      case "is_user":
        query.filter[property] = query.filter[property]
          ? [...query.filter[property], "USER"]
          : ["USER"];
        break;
      case "is_lead":
        query.filter[property] = query.filter[property]
          ? [...query.filter[property], "LEAD"]
          : ["LEAD"];
        break;
      case "is_visitor":
        query.filter[property] = query.filter[property]
          ? [...query.filter[property], "VISITOR"]
          : ["VISITOR"];
        break;
      case "is_company":
        query.filter[property] = query.filter[property]
          ? [...query.filter[property], "COMPANY"]
          : ["COMPANY"];
        hasCompanyReference = true;
        break;
      case "is_bool_false":
        query.filter[property] = query.filter[property]
          ? [...query.filter[property], "false"]
          : ["false"];
        break;
      case "not_exactly":
        if (WILDCARD_PROPERTIES.includes(property)) {
          query.not_filter[property + ".keyword"] = query.not_filter[
            property + ".keyword"
          ]
            ? [...query.not_filter[property], reference.value]
            : [reference.value];
        } else if (property.startsWith("CF-")) {
          query.not_multi_match[reference.value] = [
            `${property}.keyword`,
            `${property}_keyword`,
            `${property}_standard.keyword`,
            `${property}_whitespace.keyword`,
          ];
        } else if (property.includes("fullName")) {
          let words = reference.value.trim().split(" ");
          let wordsCount = words.length;
          if (wordsCount <= 1) {
            query.internal_or.push({
              not_filter : { "firstName.keyword": [words.pop()] },
              exists: { "field": "lastName" },
            })
          } else {
            let lastName = words.pop();
            let firstName = words.join();
            query.not_multi_match[firstName] = [`firstName.keyword`];
            query.not_multi_match[lastName] = [`lastName.keyword`];
          }
        } else
          query.not_filter[property] = query.not_filter[property]
            ? [...query.not_filter[property], reference.value]
            : [reference.value];
        break;
      case "has_no_value":
        if(property.startsWith("CF-") && (reference.type == "text" || reference.type == "String")) {
          query.not_exist["field"].push(property);
          query.not_exist["field"].push(`${property}_keyword`);
          query.not_exist["field"].push(`${property}_standard`);
          query.not_exist["field"].push(`${property}_whitespace`);
        } else {
          query.not_exist["field"].push(property);
        }
        break;
      case "has_any_value":
        if(property.startsWith("CF-") && (reference.type == "text" || reference.type == "String")) {
          query.internal_or_exists["field"].push(property);
        } else {
          query.exists["field"].push(property);
        }
        break;
      case "after_date":
        query.range.push({
          [property]: {
            gte: getAppropriateDateFormat(
              reference.isCustom,
              reference.value,
              false
            ),
          },
        });
        break;
      case "before_date":
        query.range.push({
          [property]: {
            lte: getAppropriateDateFormat(
              reference.isCustom,
              reference.value,
              true
            ),
          },
        });
        break;
      case "on_date":
        query.range.push({
          [property]: {
            gte: getAppropriateDateFormat(
              reference.isCustom,
              reference.value,
              true
            ),
            lte: getAppropriateDateFormat(
              reference.isCustom,
              reference.value,
              false
            ),
          },
        });
        break;
      case "more_then_no_days":
        dateToQuery =
          new Date().getTime() - 24 * 60 * 60 * 1000 * reference.value;
        query.range.push({
          [property]: {
            lt: getAppropriateDateFormat(reference.isCustom, dateToQuery, true),
          },
        });
        break;
        a;
      case "less_then_no_days":
        dateToQuery =
          new Date().getTime() - 24 * 60 * 60 * 1000 * reference.value;
        query.range.push({
          [property]: {
            gt: getAppropriateDateFormat(reference.isCustom, dateToQuery, true),
          },
        });
        break;
      case "equals_to":
        query.range.push({
          [property]: { gte: reference.value, lte: reference.value },
        });
        break;
      case "more_then":
        query.range.push({ [property]: { gt: reference.value } });
        break;
      case "less_then":
        query.range.push({ [property]: { lt: reference.value } });
        break;
      case "more_then_times":
        query.range.push({ [property + "_count"]: { gte: reference.value } });
        break;
      case "less_then_times":
        query.range.push({ [property + "_count"]: { lte: reference.value } });
        break;
      case "more_then_days":
        dateToQuery =
          new Date().getTime() - 24 * 60 * 60 * 1000 * reference.value;
        query.range.push({
          [property + "_mod_date"]: {
            lt: getAppropriateDateFormat(true, dateToQuery, true),
          },
        });
        break;
      case "less_then_days":
        dateToQuery =
          new Date().getTime() - 24 * 60 * 60 * 1000 * reference.value;
        query.range.push({
          [property + "_mod_date"]: {
            gte: getAppropriateDateFormat(true, dateToQuery, true),
          },
        });
        break;
      case "is_tagged":
        query.match[property] = query.match[property]
          ? [...query.match[property], reference.value]
          : [reference.value];
        break;
      case "is_not_tagged":
        query.not_match[property] = query.not_match[property]
          ? [...query.not_match[property], reference.value]
          : [reference.value];
        break;
      case EMAIL_SUBSCRIPTION_FILTER_TYPE.SUBSCRIBED :
        reference.value = "c20807be-c5c9-46bb-83ea-e4f4caca2879"
          query.filter[property] = query.filter[property]
            ? [...query.filter[property], reference.value]
            : [reference.value];
        break;
      case EMAIL_SUBSCRIPTION_FILTER_TYPE.UNSUBSCRIBED:
        reference.value = "8ccce0d9-6cee-4c1d-a7f3-a70032cdd2ce"
          query.filter[property] = query.filter[property]
            ? [...query.filter[property], reference.value]
            : [reference.value];
        break;
    }
  }

  console.log("Query formed is:", query);

  return query;
};

export const changeGroupsQueryByReference = (
  peopleGroups,
  customFields,
  projectKey
) => {
  //changing complete query by reference before requesting the count
  let peopleGroupsMap = {};
  let queryByReference = {};

  peopleGroupsMap = peopleGroups.reduce((acc, peopleGroup) => {
    queryByReference = createQueryByReference(
      JSON.parse(peopleGroup["references"]),
      peopleGroup["filterType"],
      customFields,
      projectKey
    );
    peopleGroup["completeQuery"] = JSON.stringify(queryByReference);
    acc[peopleGroup.key] = peopleGroup;
    return acc;
  }, {});

  return peopleGroupsMap;
};

export const createGroupsFromTags = (tags) => {
  //creating groups by tag for easy access in people listing page
  let peopleGroupsfromTags = {};

  tags.map((tag) => {
    if (tag.status == "ACTIVE")
      peopleGroupsfromTags[tag.key] = constructPeopleGroupFromTag(tag);
  });

  return peopleGroupsfromTags;
};

export const constructPeopleGroupFromTag = (tag) => {
  let referenceId = generateReferenceId();
  return {
    key: tag.key,
    projectId: tag.projectId,
    createdBy: tag.createdBy,
    groupName: tag.name,
    completeQuery: `{\"projectId\":\"${tag.projectId}\",\"filter\":{},\"not_filter\":{},\"match\":{\"tags\":[\"${tag.key}\"]},\"not_match\":{},\"exists\":{\"field\":[]},\"not_exist\":{\"field\":[]},\"sort\":{\"lastSeenTime\":\"desc\"},\"range\":[],\"size\":50,\"aggr\":{},\"scroll\":\"6h\",\"filterType\":\"all\"}`,
    references: `{\"${referenceId}":{\"key\":\"${referenceId}\",\"type\":\"Tags\",\"c\":false,\"isEventProp\":false,\"filterType\":\"is_tagged\",\"value\":\"${tag.key}\",\"property\":\"tags\"}}`,
    filterType: "all",
    isDefaultGroup: true,
    isCustomGroup: false,
    isTagGroup: true,
    tagType: tag.tagType,
  };
};

export const getAppropriateDateFormat = (isCustom, timestamp, isStart) => {
  if (isCustom && isStart) {
    return momentTimeZone(timestamp).startOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
   } else if (isCustom) {
    return momentTimeZone(timestamp).endOf('day').utc().format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
   } else if (isStart) {
     return momentTimeZone(timestamp).startOf('day').utc().valueOf();
   } else {
     return momentTimeZone(timestamp).endOf('day').utc().valueOf();
    }
};

export const getPropertyTypeToQuery = (property, reference) => {
  let { type } = reference;

  if (type == "text" || type == "String") return "_String";
  else return `_${type}`;

  return "";
};

export const escapeHTML = (html) => {
  let escape = document.createElement("textarea");
  escape.textContent = html;
  return escape.innerHTML;
};

export const unescapeHTML = (html) => {
  let escape = document.createElement("div");
  escape.innerHTML = html;
  return escape.textContent || escape.innerText || "";
};

export const stripHTML = (html) => {
  let escape = document.createElement("div");
  escape.innerHTML = html;
  return escape.textContent || escape.innerText || "";
};

export const postWindowMessage = (data, windowURL) => {
  console.log("postWindowMessage to parent !");
  console.log("data: ", data);
  console.log("windowURL: ", windowURL);
  window.parent.postMessage(JSON.stringify(data), windowURL);
};

export const getBrandLogoByDomainURL = () => {
  let hostName = document.location.host;
  let brandLogo = "loginLogo";

  for (var brandName in BRANDLOGO_CLASS_BY_BRANDNAME) {
    if (BRANDLOGO_CLASS_BY_BRANDNAME.hasOwnProperty(brandName)) {
      if (hostName.includes(brandName))
        brandLogo = BRANDLOGO_CLASS_BY_BRANDNAME[brandName];
    }
  }

  console.info("Brand Logo ::", brandLogo);
  return brandLogo;
};

export const sortListByProperty = (listOfObjects, propertyToSort) => {
  return listOfObjects.sort((a, b) => {
    let value1 = a[propertyToSort];
    let value2 = b[propertyToSort];

    if (typeof value1 == "string" && typeof value2 == "string") {
      value1 = value1.toLowerCase();
      value2 = value2.toLowerCase();
    }

    if (value1 < value2) {
      return -1;
    } else if (value1 > value2) {
      return 1;
    }

    return 0;
  });
};

export const getNameSortedStaffs = (staffList) => {
  staffList.sort(function (a, b) {
    if (!a.fullName) {
      a = setFullNameAndInitial(a);
    }
    if (!b.fullName) {
      b = setFullNameAndInitial(b);
    }
    if (a.fullName.toLowerCase() < b.fullName.toLowerCase()) {
      return -1;
    }
    if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return staffList;
};

export const getSortedCustomFields = (customFields) => {
  customFields.sort(function (a, b) {
    if (a.createdDate < b.createdDate) {
      return -1;
    }
    if (a.createdDate > b.createdDate) {
      return 1;
    }
    return 0;
  });
  return customFields;
};
export const isAwWindow = () => {
  let awQueryString = getQueryParam("aw");
  if (awQueryString == "true" || awQueryString == true) return true;
  return false;
};

export const getStaffUserIdWithProjectId = (staffsMap, projectId) => {
  let delimeter = "-";
  let formattedKeys = [];
  for (let staffId in staffsMap) {
    let key = projectId + delimeter + staffId;
    formattedKeys.push(key);
  }
  return formattedKeys;
};

export const getStaffUserIds = (staffsMap) => {
  let formattedKeys = [];

  for (let staffId in staffsMap) formattedKeys.push(staffId);

  return formattedKeys;
};

export const isValidTagName = (tagName) => {
  tagName = tagName.trim();
  return (
    tagName !== "" && /[a-z]|[A-Z]|\d+/g.test(tagName) && tagName.length > 1
  );
};

export const getFullName = (staff) => {
  let fullName = "";
  if (staff) {
    if (staff.firstName && staff.firstName.toLowerCase() != "null")
      fullName = staff.firstName;
    if (staff.lastName && staff.lastName.toLowerCase() != "null")
      fullName = fullName + " " + staff.lastName;
  }
  return fullName;
};

export const isValidObject = (object) => {
  if (!object) return false;

  if (typeof object !== "object") return false;

  if (Object.keys(object).length === 0) return false;

  return true;
};
export const getContactDetailsToPostToSBIFrame = (contact) => {
  if (typeof contact == "object") {
    return {
      type: "cs-contact-update",
      data: {
        firstName: contact.firstName,
        lastName: contact.lastName,
        key: contact.key,
        contactId: contact.contactId,
        email: contact.email,
        phoneNumber: contact.displayPhoneNumber,
        companyName: contact.companyName,
      },
    };
  }
};

export const getGradientColor = (actualColor) => {
  if (actualColor.toUpperCase() == "#FFFFFF") return "#E5e5e5";
  else if (actualColor.toUpperCase() == "#000000") return "#949494";
  else return color(actualColor).lighten(0.4).hex();
};
export const getDisplayTimeInTimeZone = (timeZoneId, offset, timeZoneAbbr) => {
  if (timeZoneAbbr) return getTimeInOffset(offset) + " (" + timeZoneAbbr + ")";
  else {
    let abbr = momentTimeZone().tz(timeZoneId).zoneAbbr();
    if (abbr.startsWith("+") || abbr.startsWith("-"))
      return momentTimeZone().tz(timeZoneId).format("h:mm A [(][GMT]Z[)]");
    return momentTimeZone().tz(timeZoneId).format("h:mm A [(]z[)]");
  }
};
export const getDisplayTimeWithoutAbbr = (timeZoneId, offset, timeZoneAbbr) => {
  if (timeZoneAbbr) 
    return getTimeInOffset(offset);
  else 
    return momentTimeZone().tz(timeZoneId).format("h:mm A");
};
export const isDST = (timezoneId) => {
  if (timezoneId && timezoneId !== "null")
    return momentTimeZone().tz(timezoneId).isDST();
  return false;
};
export const getTimeInOffset = (offset) => {
  let date = new Date();
  let utc = date.getTime() + date.getTimezoneOffset() * 60000;
  let offsetDate = new Date(utc + 1000 * offset);
  let hours = offsetDate.getHours();
  let minutes = offsetDate.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours == 0 && ampm == "PM" ? 12 : hours;
  minutes = (minutes < 10 ? "0" : "") + minutes;

  return hours + ":" + minutes + " " + ampm;
};

export const getAvatarColor = (contact) => {
  return contact && contact.avatarColor ? contact.avatarColor : "#A79580";
};
export const setFullNameAndInitial = (staff) => {
  if (staff.staffType === "OVERFLOW") {
    staff.fullName =
      staff.aliasName && staff.aliasName != "null" ? staff.aliasName : "";
    staff.lastName =
      staff.lastName && staff.lastName != "null"
        ? staff.lastName.charAt(0)
        : ""; // setting the lastname as initial only
  }
  if (!staff.fullName) {
    staff.fullName =
      staff.firstName && staff.firstName != "null" ? staff.firstName : "";
    staff.fullName +=
      staff.lastName && staff.lastName != "null" ? " " + staff.lastName : "";
  }
  let names = staff.fullName.split(" ");
  let initial = "";
  if (names.length > 1)
    initial = names[0].charAt(0) + names[names.length - 1].charAt(0);
  else initial = names[0].substring(0, 2);
  staff.initial = initial.toUpperCase();

  return staff;
};
export const addHttps = (url) => {
  url = url.split("://");
  url[0].includes("tp") ? url.splice(0, 1) : "";
  url = url.join();
  if (!/^https?\:\/\//.test(url)) {
    url = "https://" + url;
  }
  return url;
};

export const isURLValid = (url) => {
  var RegExp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (RegExp.test(url)) return true;
  else return false;
};

export const getProjectNameByKey = (projectKey, projectList) => {
  for (let projectEntity of projectList) {
    if (projectEntity.key === projectKey) return projectEntity.name;
  }
};

export const getProjectByKey = (projectKey, projectList) => {
  for (let projectEntity of projectList) {
    if (projectEntity.key === projectKey) return projectEntity;
  }
};

export const getGuestInfoFromPeopleEntity = (peopleEntity) => {
  return {
    name: getFullName(peopleEntity) || "",
    projectName: peopleEntity.projectName,
    totalVisits: peopleEntity.totalVisit || 1,
    key: peopleEntity.key,
    userType: peopleEntity.userType,
    currentPage: peopleEntity.currentPage,
    isOnline: peopleEntity.visitorAvailabilityStatus === "ACTIVE",
    modifiedDate: peopleEntity.modifiedDate,
    pageTitle: peopleEntity.pageTitle,
    isDeleted: false,
  };
};
export const getNewFormatedTime = (timeStamp, shouldIncludeYear) => {
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let time = new Date(timeStamp);
  let displayTime = "";
  let hours = time.getHours();
  let minutes = time.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  let date = time.getDate();
  let month = time.getMonth();
  let monthName = monthNames[time.getMonth()];
  let year = time.getFullYear();
  let currentTime = new Date();
  hours = hours % 12;
  hours = hours == 0 && ampm == "PM" ? 12 : hours;
  if (minutes < 10) minutes = "0" + minutes;

  if (shouldIncludeYear === false) {
    if (
      currentTime.getFullYear() === year &&
      currentTime.getMonth() == month &&
      currentTime.getDate() == date
    )
      return hours + ":" + minutes + " " + ampm;
    else return date + " " + monthName;
  } else {
    if (
      currentTime.getFullYear() === year &&
      currentTime.getMonth() == month &&
      currentTime.getDate() == date
    )
      return hours + ":" + minutes + " " + ampm;
    else return date + " " + monthName + " " + year;
  }
};
export const getServerTime = () => {
  return new Date().getTime() - store.getState().UserReducer.timeDifference;
};
export const getDefaultCustomDimensions = (projectId) => {
  const mode = getAppMode() == "live" ? "live" : "staging";
  return {
    projectId,
    projectType: TEST_PROJECTS[mode].includes(projectId)
      ? "TEST"
      : INTERNAL_PROJECTS[mode].includes(projectId)
      ? "INTERNAL"
      : "EXTERNAL",
  };
};

export const storeNotificationInLocalStorage = (notificationObject) => {
  store.dispatch(
    NotificationActions.addDesktopNotification(notificationObject)
  );
};

export const getNotificationFromLocalStorageAndRemove = (
  isFromStorageHandler
) => {
  let notificationList =
    store.getState().NotificationReducer.desktopNotifications || [];
  if (notificationList.length) {
    for (var i = 0; i < notificationList.length; i++) {
      notificationList[i].close();
    }
    store.dispatch(NotificationActions.removeDesktopNotification());
    if (!isFromStorageHandler)
      localStorage.setItem("shouldClearNotifications", true);
  }
};

//to get current formatted time
export const getCurrentFormattedTime = () => {
  let currentDate = new Date();
  var hours = currentDate.getHours();
  var ampm = hours >= 12 ? " PM" : " AM";
  hours = hours % 12 === 0 ? 12 : hours % 12;
  hours = hours == 0 && ampm == " PM" ? 12 : hours;

  let minutes =
    currentDate.getMinutes() < 10
      ? "0" + currentDate.getMinutes()
      : currentDate.getMinutes();
  return hours + ":" + minutes + ampm;
};

//to get formatted time  of the timzone
export const getDiffTimezoneTime = (offset) => {
  let convertedTime = getConvertedTime(offset);
  var hours = convertedTime.getHours();
  var ampm = hours >= 12 ? " PM" : " AM";
  hours = hours % 12 === 0 ? 12 : hours % 12;
  hours = hours == 0 && ampm == " PM" ? 12 : hours;

  let minutes =
    convertedTime.getMinutes() < 10
      ? "0" + convertedTime.getMinutes()
      : convertedTime.getMinutes();
  return hours + ":" + minutes + ampm;
};

//to get converted date of different timezone
export const getConvertedTime = (offset) => {
  let date = new Date();
  let utcTime = date.getTime() + date.getTimezoneOffset() * 60000;
  let convertedTime = new Date(utcTime + offset);
  return convertedTime;
};

export const createUUId = () => {
  var d = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-yyyy-yxxx-xxxxxxxxxxxx".replace(/[x4y]/g, function (
    c
  ) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
  return uuid;
};
export const getDaysDifference = (createdDate) => {
  let messagedTime = new Date(createdDate);
  let currentTime = new Date();
  if (messagedTime.getFullYear() !== currentTime.getFullYear()) {
    return 3;
  }
  if (messagedTime.getMonth() == currentTime.getMonth())
    return currentTime.getDate() - messagedTime.getDate();
  else if (
    currentTime.getDate() == 1 &&
    currentTime.getMonth() == messagedTime.getMonth() + 1
  ) {
    let monthsWith31Days = [0, 2, 4, 6, 7, 9, 11];
    if (messagedTime.getMonth() == 1 && messagedTime.getDate() == 28) return 1;
    else if (
      monthsWith31Days.includes(messagedTime.getMonth()) &&
      messagedTime.getDate() == 31
    )
      return 1;
    else if (messagedTime.getDate() == 30) return 1;
    else return 3;
  }
  return 3;
};
export const getDateOfMessage = (createdDate) => {
  return new Date(createdDate).getDate();
};

export const reArrangeVistiorList = (visitorList) => {
  let activeObj = {};
  let inActiveObj = {};
  for (let visitorId in visitorList) {
    if (visitorList[visitorId].isOnline == true) {
      activeObj[visitorId] = visitorList[visitorId];
    } else {
      inActiveObj[visitorId] = visitorList[visitorId];
    }
  }

  return { ...activeObj, ...inActiveObj };
};

export const getCountryCode = (countryCode) => {
  let countryDetails = ctzc.getCountryByCode(countryCode);
  return countryDetails && countryDetails.areaCode;
};

export const getFormattedTimeFromMinutes = (min) => {
  min = min ? min : 0;
  let hours = Math.floor(min / 60);
  let amOrPm = hours >= 12 ? " PM" : " AM";
  hours = hours % 12 == 0 ? 12 : hours % 12;
  let minutes = min % 60;
  return (
    (("" + hours).length < 2 ? "0" + hours : hours) +
    ":" +
    (("" + minutes).length < 2 ? "0" + minutes : minutes) +
    amOrPm
  );
};

export const getVisitorDisplayTime = (timeZoneObject) => {
  let displayTime = "";
  displayTime = timeZoneObject
        ? getDisplayTimeWithoutAbbr(
            timeZoneObject.timeZoneId,
            timeZoneObject.dstOffset + timeZoneObject.rawOffset,
            timeZoneObject.timeZoneAbbr
          )
        : "";
  return displayTime;
}

export const getDisplayPlace = (timeZoneObject, country) => {
  let displayPlace = "";
  if (timeZoneObject) {
    if (timeZoneObject.city && timeZoneObject.city !== "null")
      displayPlace = timeZoneObject.city;
    else if (timeZoneObject.state && timeZoneObject.state !== "null")
      displayPlace = timeZoneObject.state;
    if (country && country != "null" && country != "ZZ")
      displayPlace += displayPlace ? ", " + country : country;
    else if (
      timeZoneObject.shortCountry &&
      timeZoneObject.shortCountry !== "null"
    )
      displayPlace += displayPlace
        ? ", " + timeZoneObject.shortCountry
        : timeZoneObject.shortCountry;
    else if (timeZoneObject.country && timeZoneObject.country !== "null")
      displayPlace += displayPlace
        ? ", " + timeZoneObject.country
        : timeZoneObject.country;
  }
  if (timeZoneObject && timeZoneObject.unformattedLocation) {
    displayPlace = timeZoneObject.unformattedLocation;
  }
  return displayPlace;
}

export const generateHashCode = (id) => {
  return Crypto.createHash("MD5").update(id).digest("hex");
};

export const getNonDefaultVersion = () => {
  let hostname = document.location.hostname;
  let nonDefaultSuffix =
    getAppMode() == "live"
      ? "-dot-webapp-dot-livesupport-app.appspot.com"
      : "-dot-webapp-dot-staging-live-support.appspot.com";
  return hostname.substring(0, hostname.indexOf(nonDefaultSuffix));
};

export const getScreenShareLink = (videoLink, appendString, path) => {
  let position = videoLink.indexOf(path);
  if (position !== -1) {
    return (
      videoLink.substr(0, position) + appendString + videoLink.substr(position)
    );
  }
  return "substring not found";
};
export const getUserPermissionUtility = (feature) => {
  return isUserHavingPermission(feature);
};

const getIsSettingsComponent = () => {
  return process.env.isSettingsComponent;
}

export const getIsAWRecentComponent = () => {
  return process.env.isAWRecentComponent;
}

export const getModeForSettingsComponent = () => {
  return localStorage.getItem(SETTINGS_COMPONENT_MODE);
}

export const getModeForAWRecentComponent = () => {
  if(FrontOfficeService){
    return FrontOfficeService.App.getAppEnvironment();
  }
  return "staging";
}

export const getApiVersion = () => {
  let nonDefaultWebappVersion = getNonDefaultVersion();
  let appMode = getAppMode();
   if (nonDefaultWebappVersion)
    return (
      "https://" +
      nonDefaultWebappVersion +
      (appMode == "live"
        ? "-dot-livesupport-app.appspot.com/api"
        : "-dot-staging-live-support.appspot.com/api")
    );
  else
    return appMode == "live"
      ? "https://api.chatsupport.co/api"
      : "https://staging.api.chatsupport.co/api";
};
export const getWebhookVersion = () => {
  let nonDefaultWebappVersion = getNonDefaultVersion();
  let appMode = getAppMode();
  if (nonDefaultWebappVersion) {
    let nonDefaulthost =
      "https://" +
      nonDefaultWebappVersion +
      (appMode == "live"
        ? "-dot-rtm-livesupport-dot-livesupport-app.appspot.com/"
        : "-dot-rtm-livesupport-dot-staging-live-support.appspot.com/");
    return { isNondefault: true, nonDefaulthost: nonDefaulthost };
  } else return { isNondefault: false, nonDefaulthost: undefined };
};

export const defaultWebHookVersion = () => {
  let appMode = getAppMode();
  if(appMode === 'live') 
    return "https://webhook.chatsupport.co"
  else 
    return "https://staging.webhook.chatsupport.co";
}

export const constructNonDefaultHooks = (nonDefaulthost) => {
  const chatWebhookUrl = nonDefaulthost + "save";
  const overflowHookUrl = nonDefaulthost + "saveOverflowedMessage";
  const disconnectHookUrl = nonDefaulthost + "disconnect";

  let nonDefaultHooks = {};

  nonDefaultHooks["chat"] = [chatWebhookUrl];
  nonDefaultHooks["server-disconnect"] = [disconnectHookUrl];
  nonDefaultHooks["routing-interaction-callended"] = [overflowHookUrl];
  nonDefaultHooks["routing-interaction-ended"] = [overflowHookUrl];
  nonDefaultHooks["routing-interaction-queued"] = [overflowHookUrl];
  nonDefaultHooks["routing-interaction-overflowed"] = [overflowHookUrl];
  nonDefaultHooks["routing-interaction-delivered"] = [overflowHookUrl];
  nonDefaultHooks["routing-interaction-dialout"] = [overflowHookUrl];
  nonDefaultHooks["routing-interaction-accepted"] = [overflowHookUrl];
  nonDefaultHooks["routing-interaction-unaccepted"] = [overflowHookUrl];
  nonDefaultHooks["routing-interaction-routed"] = [overflowHookUrl];
  nonDefaultHooks["routing-interaction-triggered"] = [overflowHookUrl];

  return nonDefaultHooks;
};

export const getViewportHeight = () => {
  return Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
};

export const isAnnoucementDesktopType = (campaignDetails) => {
  return (
    campaignDetails &&
    campaignDetails.campaignType == "ANNOUNCEMENT" &&
    campaignDetails.previewType == "Desktop"
  );
};

export const isEmailDesktopType = (campaignDetails) => {
  return (
    campaignDetails &&
    campaignDetails.campaignType == "EMAIL" &&
    campaignDetails.previewType == "Desktop"
  );
};
export const getRandomColor = () => {
  let backgroundColor = "";
  let color = [
    "#F1653A",
    "#F2C241",
    "#EDC69F",
    "#68B3C4",
    "#19989F",
    "#7D7773",
    "#1E629F",
    "#5FA476",
    "#7FC68D",
    "#CF3F55",
    "#F2C241",
    "#64BA72",
    "#DD5A50",
    "#93C9E4",
    "#EF7146",
    "#7466A7",
    "#B1608B",
    "#AECFD7",
    "#C67EBF",
    "#A79580",
  ];
  backgroundColor = color[Math.floor(Math.random() * color.length)];
  return backgroundColor;
};

/**
 * Converts hex to rgba
 * @param {*} hex
 */
export const hexToRgbA = (hex) => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") + ",1)"
    );
  }
  throw new Error("Bad Hex");
};

/**
 * checks if an array has matching elements
 * @param {*} list
 * @param {*} property
 * @param {*} value
 */
export const hasMatchingElements = (list, property, value, excludeKey = "") => {
  let matchingElements = [];
  matchingElements = list.filter((element) => {
    return (
      element[property] &&
      element[property].trim().toLowerCase() == value.trim().toLowerCase() &&
      element["key"] !== excludeKey &&
      element["status"] == "ACTIVE"
    );
  });
  return matchingElements.length > 0;
};

const getProcessedMessageWithFallback = (patternMessage) => {
  var pattern = /\{\{(.*?)\}\}/g;
  let fromPattern = /<p><span style="display: block; font-size: 12px; opacity: 0.5; line-height: 1.67;">(.*?)<\/p>/g;
  patternMessage = patternMessage.replace(fromPattern, "+_from_");
  var matches = patternMessage.match(pattern);
  let replaceValues = {
    firstName: "{{firstName}}",
    lastName: "{{lastName}}",
    fullName: "{{fullName}}",
    companyName: "{{companyName}}",
  };
  if (matches && matches.length) {
    return matches.reduce((patternMessage, value) => {
      let val = value.substring(2, value.length - 2);
      let values = val.split("|");
      return patternMessage.replace(
        value,
        `${replaceValues[values[0].trim()]}`
      );
    }, patternMessage);
  }

  return patternMessage;
};

export const getReplacedMessage = (patternMessage) => {
  let processedMessage = getProcessedMessageWithFallback(patternMessage);
  return processedMessage;
};

export const getFallBackValues = (patternMessage) => {
  var pattern = /\{\{(.*?)\}\}/g;
  var matches = patternMessage.match(pattern);
  let fallBackValues = {};
  let replaceValues = {
    firstName: "{{firstName}}",
    lastName: "{{lastName}}",
    fullName: "{{fullName}}",
    companyName: "{{companyName}}",
  };
  if (matches && matches.length) {
    matches.map((value) => {
      let val = value.substring(2, value.length - 2);
      let values = val.split("|");
      if (values[0] && values[1]) {
        let keyName = replaceValues[values[0].trim()];
        fallBackValues[keyName] = values[1].trim();
      }
    }, patternMessage);
  }
  return fallBackValues;
};

export const downloadCSV = (csvUrl, fileName) => {
  let link = document.createElement("a");
  link.href = csvUrl;
  link.setAttribute("download", fileName);
  link.click();
  return link;
};

export const getTypingStopMessage = (conversationId,visitorId,projectId) => {
  return {
    messageId: generateMessageId(),
    message: "",
    timestamp: new Date().getTime(),
    conversationId: conversationId,
    visitorId: visitorId,
    projectId: projectId,
    type: TYPING_STATUS.STOP,
    messageFrom: MESSAGE_FROM.VISITOR,
    assignedTo: "",
    read: false,
    interactionType: INTERACTION_TYPE.OVERFLOW,
  };
};

export const getSelectionColorBasedOnUserAgent = ( userAgent ) => {
  if (userAgent.indexOf("Win") != -1) 
    return "#3297FD"; 
  else if (userAgent.indexOf("Mac") != -1) 
    return "#ACCEF7"; 
  else 
    return "#ACCEF7"
       
}
export const getfilterQueryArray = (filterQuery) => {
  let filterQueryObj = filterQuery;
  let responseArr = [];
  for (let key in filterQueryObj) {
    responseArr.push(filterQueryObj[key]);
  }
  return responseArr;
};
export const getSelectedCampaignMessage = (selectedCampaign) =>{
  if(selectedCampaign.message && selectedCampaign.message.value != undefined )
    return selectedCampaign.message.value;
  else if(selectedCampaign.message)
    return selectedCampaign.message;
}
export const constructNewCampaign = (projectkey, projects, type, campaignDetails, groupId) => {
  let projectId = getProjectId(projectkey);
  const newCampaignData = Object.assign({}, defaultCampaignData);
  newCampaignData.projectId = projectId;
  newCampaignData.campaignFrom = "";
  newCampaignData.campaignFromName = "";
  newCampaignData.campaignFromImage = "";
  newCampaignData.typeOfMessage = campaignDetails ? campaignDetails.campaignType: "";
  newCampaignData.subject = "";
  newCampaignData.senderId = "";
  newCampaignData.isNewCampaign = true;
  newCampaignData.projectName = getProjectNameByKey(
    projectId,
    projects && projects.projectList ? projects.projectList : []
  );
  newCampaignData.fallBackValues = {
    "{{firstName}}": "there",
  };
  
  let welcomeMessage = "";
  let defaultHtml = "";
  if ( type == "chat" || type == "email")
  {
    welcomeMessage = "Hi First Name,";
    defaultHtml = `<p>${welcomeMessage}<div>+_add_content</div></p><div><p>+_from_</p></div>`;
  }
  else if ( type == "announcement" )
  {
    welcomeMessage = "Hi there,";
    defaultHtml = `<p>${welcomeMessage}<div>+_add_content</div></p><p>+_sender_info</p>`;
  }
  newCampaignData.message = defaultHtml;
  if (groupId) {
    newCampaignData.segmentIds = [groupId];
  }
  newCampaignData.endDate = 0;
  return newCampaignData;
}

export const comparePrevious = (previousValue, currentValue) => {
  return previousValue && currentValue;
};

export const isClosedByMentionedAgent = (userId , currentConversationObject) => {
  let currentMentionedStaffs = currentConversationObject.mentionedStaffs || [];
  return (currentMentionedStaffs.includes(userId))
}
export const isSingleAgentConversationClosed = (userId, currentConversationObject)=>{
  let currentOpenAssignedAgents = currentConversationObject.chatStatus == CHAT_STATUS.Closed && currentConversationObject.assignedAgentsObject && currentConversationObject.assignedAgentsObject.open ? currentConversationObject.assignedAgentsObject.open : [];
  return (currentOpenAssignedAgents.includes(userId));
}

export const sendMentionedCloseMessage = (mentionCloseMessage  , isOverFlowConversation) => {
  console.log("Store is " , store);
  let meta = {
    echoChannels: ["private/agent/" + mentionCloseMessage.projectId ],
  };
  store.dispatch(
    sendMessage(
      mentionCloseMessage,
      meta,
      isOverFlowConversation
    )
  );
}
export const processAndSendEmailMessage = (
  isEmailConversation,
  isNotesActive,
  chtMessage,
  message,
  isOverflowConversation, 
  interactionId
) => {
  if (isEmailConversation && !isNotesActive) {
    chtMessage.message = message;
    chtMessage.messageStatus = "CHAT_SENT";
    store.dispatch(MessageActions.addMessage(chtMessage));
    store.dispatch(MessageActions.sendEmailMessage(chtMessage));
    store.dispatch(MessageActions.sendMessage(chtMessage, 
      isOverflowConversation
      ? { interactionId }
      : {
          echoChannels: ["private/agent/" + chtMessage.projectId],
        }));
  }
};

export const validateContact = (contact) => {
  if (!contact.userId && !contact.key) {
    let ErrorMessage = new Error();
    ErrorMessage.message = ERROR_MESSAGE.CONTACT_DETAILS_MISSING.MESSAGE;
    ErrorMessage.stack = ERROR_MESSAGE.CONTACT_DETAILS_MISSING.STACK;
    store.dispatch(mailGlobalError(ErrorMessage));
    return false;
  }
  return true;
};

export const isValidPresencePayload = (userPresence) => {
  return isValidObject(userPresence);
};

export const handleInvalidPresencePayload = (userId) => {
  let ErrorMessage = new Error();
  ErrorMessage.message = ERROR_MESSAGE.INVALID_AW_USER_PRESENCE_PAYLOAD.MESSAGE;
  ErrorMessage.stack =
    ERROR_MESSAGE.INVALID_AW_USER_PRESENCE_PAYLOAD.STACK + userId;
  store.dispatch(mailGlobalError(ErrorMessage));
};

export const sendSingleAgentConversationClosedMessage = (singleConversationClosedMessage  , isOverFlowConversation) => {
  console.log("Store is " , store);
  let meta = {
    echoChannels: ["private/agent/" + singleConversationClosedMessage.projectId ],
  };
  store.dispatch(
    sendMessage(
      singleConversationClosedMessage,
      meta,
      isOverFlowConversation
    )
  );
}
export const getIconUrl = (origin,isBlue) => {
  let domainName = origin.split("-")[0];
  if (domainName.includes("answerforce"))
    return `${origin}/images/answerforce-icon.svg`;
  if(isBlue)
    return `https://assets.chatsupport.co/website/stag/images/chatsupport-circle-logo.png`;
  return `https://assets.chatsupport.co/website/stag/images/chatsupport-circle-logo.png`;
};

export const getLogoUrl = (origin) => {
  if (origin && origin.includes("answerforce"))
      return  `${origin}/images/answerforce-logo.svg`;
  return 'https://assets.chatsupport.co/website/stag/images/chatsupport-brand-app.svg';
};

export const capitalizeFirstLetter = (string) => {
  return string.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
    .split('.')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join('.')
    .split('-')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join('-');
};
 
export const getNextFeedbackMessage = (createdDate, feedbackMessageMap) => {
  for (let feedbackCreatedDate in feedbackMessageMap) {
    if (createdDate < feedbackCreatedDate) {
      let feedbackMessage = Object.assign(feedbackMessageMap[feedbackCreatedDate],{});
      delete feedbackMessageMap[feedbackCreatedDate];
      return feedbackMessage;
    }
  }
  return {};
};

export const addToAssignedObject = (conversation, newAgentId)=>{

  let assignedAgentsObject = conversation.assignedAgentsObject;
  let open = [];
  let closed = [];
  if(assignedAgentsObject)
  {
    open = assignedAgentsObject.open;
    closed = assignedAgentsObject.closed;
    if(closed && closed.includes(newAgentId))
      closed = closed.filter(agentId=>agentId!=newAgentId);

    if(open)
    {
      if( !open.includes(newAgentId) )
        open.push(newAgentId);
    }
    else
      open = [newAgentId];
  }
  else {
    open = [newAgentId];
  }

  assignedAgentsObject = { open, closed }

  return assignedAgentsObject;
}
export const removeFromAssignedObject = (conversation, toBeRemovedAgentId, agentPresence) =>{
  let assignedAgentsObject = conversation.assignedAgentsObject;
  let open = [];
  let closed = [];
  if(assignedAgentsObject)
  {
    open = assignedAgentsObject.open;
    closed = assignedAgentsObject.closed;
    if(open && open.includes(toBeRemovedAgentId))
      open = open.filter(agentId=>agentId!=toBeRemovedAgentId);

    if(closed)
    {
      if( !closed.includes(toBeRemovedAgentId) )
        closed.push(toBeRemovedAgentId);
    }
    else
      closed = [toBeRemovedAgentId];
  }
  else {
    closed = [toBeRemovedAgentId];
  }
  if (agentPresence && agentPresence.isOnline && !agentPresence.isTakingChat ) {
    closed = [];
    open = [toBeRemovedAgentId];
  }
  assignedAgentsObject = { open, closed }

  return assignedAgentsObject;
}

export const getAgentPresence = ( agentId , projectkey, staffMap ) => {
  //deploy commit
   let staff = staffMap ? (staffMap[agentId] || {}) : {};
   let isOnline = staff.isOnline || false;
   let isTakingChat = staff.isTakingChat || false;
   return {isOnline , isTakingChat}
}

export const flipObject = (object) => {
  const flippedObject = {};
  Object.keys(object).forEach(key => {
    flippedObject[object[key]] = key;
  });
  return flippedObject;
}

export const arraysEqual = (array1,array2) => {
  array1 = array1.sort();
  array2 = array2.sort();
  return Array.isArray(array1) &&
  Array.isArray(array2) &&
  array1.length === array2.length &&
  array1.every((val, index) => val === array2[index]);
}

export const getURLRestrictionContentConfig = (componentType) => {
  const blocklist_config = {
    title : "Excluded URL List",
    description: "Enter the URL of the pages where the widget should not appear"
  }

  const allowlist_config = {
    title : "Allowed URL List",
    description: "Enter the URL of the pages where the widget should appear",
  }

  if(componentType === 'allowlist')
    return allowlist_config
  else if(componentType === "blocklist")
    return blocklist_config;
  else 
    return {};
}

export const getAllIndexes = (arr, val) => {
  var indexes = [], i = -1;
  while ((i = arr.indexOf(val, i+1)) != -1){
      indexes.push(i);
  }
  return indexes;
}

const updateImageInfoOnMessagePayload = ( fileInfo , constructedMessage ) => {
  let {fileType , imageInfo , img_serve_url } = fileInfo;
  if(fileType  === 'image'){
    constructedMessage["imageInfo"] = imageInfo;
    constructedMessage["img_serve_url"] = img_serve_url;
    return constructedMessage;
  } else {
    return constructedMessage;
  }
}

export const constructAttachmentMessage = (uploadedFileDetails) => {
  let message = {};
  message["file_id"] = uploadedFileDetails.id;
  message["fileLocation"] = uploadedFileDetails.raw_url;
  message["size"] = uploadedFileDetails.size;
  message["type"] = uploadedFileDetails.mimeType;
  message["filePath"] = uploadedFileDetails.raw_url;
  message["fileName"] = uploadedFileDetails.title;
  message["raw_url_download"] = uploadedFileDetails.raw_url_download;
  message["fileType"] = uploadedFileDetails.fileType;
  message = updateImageInfoOnMessagePayload(uploadedFileDetails , message);
  return message;
}

export const isValidWidgetText = (widgetText) => {
  if(widgetText === "")
    return true;
  if(widgetText && widgetText.trim().length === 1)
    return false;
  if(widgetText && widgetText.length > 24)
    return false;
  return true;
}

export const checkAndGetPendingClosedMessage = ( closingMessageToAgents , event ) => {
  let dataset = event.target.dataset || {};
  let closeType = dataset.closetype;
  if(closeType === "pending"){
    closingMessageToAgents.isClosedAsPending = (closeType === "pending")
    closingMessageToAgents.type = MESSAGE_TYPE.chat_closed_as_pending;
    return closingMessageToAgents;
  } else {
    return closingMessageToAgents;
  }
}

const SbCommunicationObject =  {
  opt : "accountOpt",
  name : 'sbCommunication',
  accountOpt : {
      url: window.location.href,
      name: 'accountOpt', 
      opt: 'unLoad'
  }
}

export const v2communicationObject = () => {
  let userDataFromState =  store.getState().UserReducer;
  let currentTabIndexValue = getQueryParam("currentTabIndex") || userDataFromState.fullscreenQuery["currentTabIndex"];
  let connectionId = getQueryParam("connection_id") || userDataFromState.fullscreenQuery["connection_id"];
  return {"name": "chromeAppAPI",
  "opt": "close",
  "applicationName": window.location.host,
  "status": {
    "name": "status",
    "pushStatus": "Available"
  },
  "outbound": {
    "name": null,
    "phoneNumber": null
  },
  "close": {
    "name": "close",
    "tabIndex": currentTabIndexValue
  },
  "logACK": {
    "name": "logACK",
    "ack": true,
    "host": window.location.host
  },
  "logs": {
    "name": "logs",
    "logObj": null
  },
  "originalEvent": {
    "name": "v2Communication",
    "opt": "statusPush",
    "statusPush": "Available",
    "isInterruptible": false,
    "connId": connectionId,
    "close": false,
    "tabIndex": currentTabIndexValue,
    "interactionId": connectionId,
    "source": window.location.href,
    "calltype": "epCustom"
  }}
};

export const sendCloseSignal = ()=> {
  if(FULLClient){
    closeActiveInteractionsOnF8()
      .then(()=>{
        FULLClient.ipc.sendToSB(v2communicationObject());
      })
    
  }
}

export const searchToObject = () => {
  var pairs = window.location.search.substring(1).split("&"),
    obj = {},
    pair,
    i;
  for (i in pairs) {
    if (pairs[i] === "") continue;

    pair = pairs[i].split("=");
    obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
  }

  return obj;
};
export const getCurrentTimeStamp = () => {
  return new Date().getTime();
}


export const sendAcceptedMessage = () => {
    
  let { fullscreenQuery } = store.getState().UserReducer;
  let messageReducer = store.getState().MessageReducer;
  let query = searchToObject() || fullscreenQuery
  if (
    messageReducer.isConnected &&
    messageReducer.isSubscribedToChannels &&
    query && query.isFullscreen
  ) {
    let conversationId = query.conversation_id;
    let acceptedMessage = {};
    
      let agentInfo = {};
      agentInfo.key = query.agent_id;
      agentInfo.userId = query.agent_id;
      agentInfo.firstName = query.agent_first_name;
      agentInfo.lastName = query.agent_last_name;
      agentInfo.name = agentInfo.firstName + " " + agentInfo.lastName;
      agentInfo.photoUrl = query.agent_photo_url;
      agentInfo.agentLogin = query.agent_login;
      agentInfo.login = query.agent_login;
      agentInfo.messageFrom = "AGENT";
      agentInfo.projectId = query.project_id;
      agentInfo.contactId = query.agent_id;
      agentInfo.aliasName = query.alias_name;

      console.log("The agent Info :", agentInfo);

      let visitorId = query.visitor_id;
      acceptedMessage = {
        channel: "server/routing",
        type: MESSAGE_TYPE.chat_auto_assign_on_overflow,
        message: "",
        actionTo: agentInfo.userId,
        senderId: agentInfo.userId,
        conversationId: conversationId,
        messageFrom: "AGENT",
        messageId: generateMessageId(),
        visitorId: visitorId,
        projectId: "LS-"+agentInfo.projectId,
        timestamp: getServerTime(),
        isFullScreen : true
      };
      store.dispatch(
        sendMessage(acceptedMessage, {
          interactionId: query.connection_id,
        })
      );
      console.log("Accepted signal sent :" , acceptedMessage);
    
    let statusTrackerInfo = {
      connectionId: query.connection_id,
      tool: ["Overflow"],
      isConnected: true,
      loadedDate: getServerTime(),
    };

    store.dispatch(
      trackChatStatus(statusTrackerInfo)
    );
    let staffMap = store.getState().staffReducer.dashboardAgents[agentInfo.projectId];
    let userId = store.getState().UserReducer.data ? store.getState().UserReducer.data.id : "";
    store.dispatch(
      updateConversationRealTime(acceptedMessage, userId, staffMap)
  );
  } 
}

export const triggerInteractionEnd = (payload) => {
  let host = defaultWebHookVersion();
  let url = `${host}/interaction/end`;
  return fetch( url, {
    method: 'POST',
     headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload) // body data type must match "Content-Type" header
  });

};

export const isValidSBEvent = (sbEvent) => {
  return sbEvent && typeof sbEvent == "string";
};

const closeActiveInteractionsOnF8 = () => {
  let { fullscreenQuery } = store.getState().UserReducer;
  let query = searchToObject() || fullscreenQuery;
  if(isValidObject(query)){
    let {agent_id , connection_id} = query;
    let payload = {
      "contactId": agent_id,
      "connectionId": connection_id
    }
    return triggerInteractionEnd(payload);
  } else {
    return Promise.resolve();
  }
}

export const isScrollReachedUptoThePixelsFromBottom = (target, pixels) => {
  let { scrollHeight, scrollTop, clientHeight } = target;
  return scrollHeight - scrollTop - clientHeight <= pixels;
}

export const isScrollReachedBottom = (target) => {
  return isScrollReachedUptoThePixelsFromBottom(target, 1);
}

export const getSessionForSettingsComponentByAccessToken = (accessToken) => {
  let userInfo = { token: accessToken }
  let url = getAPIBaseUrl() + "/user/settings/authenticate";

  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userInfo),
  })
}

export const checkAndAddBabelPolyfil = () => {
  if (!global._babelPolyfill) {
    require('@babel/polyfill')
  }
}
export const addBlankTargetTo = (element) =>{
  let targetAttribute = element.getAttribute('target');
  if( !(targetAttribute && targetAttribute.toLowerCase() == "_blank"))
    element.setAttribute('target','_blank');
}

export const stripTags = (htmlContent, tag) => {
  var document = new DOMParser().parseFromString(htmlContent, "text/html");
  var tags = document.getElementsByTagName(tag);
  var lengthOfTags = tags.length;
  while (lengthOfTags--) {
    if(tag ==="a")
      addBlankTargetTo(tags[lengthOfTags]);
    else
      tags[lengthOfTags].parentNode.removeChild(tags[lengthOfTags]);
  }
  return document.documentElement.outerHTML;
};

export const stripOnError = (htmlString) => {
  let document = new DOMParser().parseFromString(htmlString, "text/html");
  let nodes = document.querySelectorAll("*");
  nodes.forEach((node) => node.removeAttribute("onerror"));
  return document.documentElement.outerHTML;
};

export const stripsHtmlContent = (htmlContent) => {
  htmlContent = stripOnError(htmlContent);
  htmlContent = stripTags(htmlContent, "link");
  htmlContent = stripTags(htmlContent, "a");
  return stripTags(htmlContent, "script");
};

export const injectBillingStyle = () => {
  const fileref=document.createElement("link");
  fileref.setAttribute("rel", "stylesheet");
  fileref.setAttribute("type", "text/css");
  fileref.setAttribute("href", "https://storage.googleapis.com/front-office/global-styles/v0.3/main.css");
  return document.getElementsByTagName("head")[0].appendChild(fileref);
}

export const canShowBilling = (userDetails) => {
  const user =
    userDetails.data && userDetails.data.hasOwnProperty("contact")
      ? userDetails.data.contact
      : {};
  const planType =
    userDetails && userDetails.hasOwnProperty("planType")
    ? userDetails.planType
      : "";
  const brandId = user.hasOwnProperty("brandID") ? user.brandID : "";
  if (CHATSUPPORT_BRAND_ID == brandId && FREE_PLAN != planType) return true;
  return false;
}
export const isHTMLContent = (content)=>{
  return content.includes("<html>") || content.includes("</html>");
}

export const isEnterKey = (event) => {
  return event.keyCode == 13 || event.which == 13;
};

export const isUpArrowKey = (event) => {
  return event.keyCode == 38 || event.which == 38;
};

export const isDownArrowKey = (event) => {
  return event.keyCode == 40 || event.which == 40;
};

export const isEscKey = (event) => {
  return event.keyCode == 27 || event.which == 27;
}

export const isSpaceKey = (event) => {
  return event.keyCode == 32 || event.which == 32;
}

export const getSelectedProjectKey = () => {
  const { UserReducer } = store.getState();
  return UserReducer.selectedProjectKey;
};

export const getLocalDateStartTimeOfDay = () => {
  let start = new Date();
  start.setHours(0, 0, 0, 0);
  return start.getTime();
};

export const getLocalDateEndTimeOfDay = () => {
  var end = new Date();
  end.setHours(23, 59, 59, 999);
  return end.getTime();
};

export const getProjectIdFromStaffReducer = () => {
  const { StaffReducer } = store.getState();
  return StaffReducer.activeProjectId;
};

export const getActiveProjectId = () => {
  return isValidString(getSelectedProjectKey())
    ? getProjectId(getSelectedProjectKey())
    : getProjectIdFromStaffReducer();
};

export const getValueFromNestedObject = (object, path) => {
  let nestedProperties = path.split(".");
  return nestedProperties.reduce((obj, nestedProperty) => {
    return obj ? obj[nestedProperty] : obj;
  }, object);
};
export const canShowUpgrade = (projectMap) => {
  for (var key in projectMap) {
    if (
      projectMap[key].hasOwnProperty("subAccountNumber") &&
      isValidString(projectMap[key].subAccountNumber)
    )
      return false;
  }
  return !(projectMap && Object.keys(projectMap).length == 0);
};

export const canShowSideBarProIcon = (props) => {
  return (!props.user.isInternalUser && canShowUpgrade(props.chatConfiguration.data) && !canShowBilling(props.user))
};

export const createFileFromBlob = ( blob , fileName , type , size ) => {
  return new File([blob], fileName , {
    type,
  });
}
export const isHTMLTemplateEnabled = (message)=>{
  try {
    let constructedMessage = JSON.parse(message);
    return constructedMessage.isHTMLTemplateEnabled;
  } catch (error) {
    return false;
  }
}

export const getValidMessage = (message)=>{
  try {
    let constructedMessage = JSON.parse(message);
    return constructedMessage.message;
  } catch (error) {
    return message;
  }
}

export const getStyleOfPreviewTyping = (disabled) => {
  return { display: disabled ? "none" : "block" };
};

export const hasCampaignSubjectChanged = (
  currentCampaignInfo,
  initialCampaignInfo
) => {
  return currentCampaignInfo.subject !== initialCampaignInfo.subject;
};

export const hasReplyOptionChanged = (
  currentCampaignInfo,
  initialCampaignInfo
) => {
  return (
    currentCampaignInfo.isInteractableDisabled !==
    initialCampaignInfo.isInteractableDisabled
  );
};

export const hasCampaignSenderInfoChanged = (
  currentCampaignInfo,
  initialCampaignInfo
) => {
  return (
    currentCampaignInfo.campaignFromName !== initialCampaignInfo.campaignFromName
  );
};

export const hasCampaignDateInfoChanged = (
  currentCampaignInfo,
  initialCampaignInfo
) => {
  return (
    currentCampaignInfo.startDate !== initialCampaignInfo.startDate ||
    currentCampaignInfo.endDate !== initialCampaignInfo.endDate
  );
};
export const hasCampaignNameChanged = (
  currentCampaignInfo,
  initialCampaignInfo
) => {
  return currentCampaignInfo.campaignName !== initialCampaignInfo.campaignName;
};

export const hasCampaignInfoUpdated = (
  currentCampaignInfo,
  initialCampaignInfo
) => {
  return (
    hasReplyOptionChanged(currentCampaignInfo, initialCampaignInfo) ||
    hasCampaignNameChanged(currentCampaignInfo, initialCampaignInfo) ||
    hasCampaignDateInfoChanged(currentCampaignInfo, initialCampaignInfo) ||
    hasCampaignSenderInfoChanged(currentCampaignInfo, initialCampaignInfo) ||
    hasCampaignSubjectChanged(currentCampaignInfo, initialCampaignInfo) ||
    currentCampaignInfo.isContentUpdated
  );
};

export const isAnnouncementCampaign = (campaignDetails) => {
  return (
    campaignDetails &&
    campaignDetails.selectedCampaign &&
    campaignDetails.selectedCampaign.typeOfMessage === campaignTypes.announcement
  );
};

export const getPayloadOfShowVoicBoxForErrorMessage = (message) => {
  return {
    message: message,
    dismissAfter: 3000,
    showBelowHeader: true,
    showError: true,
  };
};

export const sendImpactTrackingForTrailSubscription = ({userId , accountId , login , planId }) => {
  
  if(window.ire){
    window.ire('trackConversion', 28370, {
      "orderId": accountId,
      "customerId": userId,
      "customerEmail": sha1(login),
      "customerStatus": "New",
      "currencyCode": "USD",
      "orderPromoCode" : "",
      "orderDiscount" : 0.0,
      "items": [
        {
          "subTotal": 0.0,
          "category": PLAN_NAME_DETAILS[planId],
          "sku": planId,
          "quantity": 1,
          "name": "Trial subscription"
        }
      ]
    },
    {
      verifySiteDefinitionMatch:true
    }
    );
    localStorage.setItem('impactTrackStatus' , true);
  }
}

export const getTypeOfStats = (typeOfStats) => {
  if (typeOfStats == "delivered") {
    return "Sent";
  } else if (typeOfStats == "opened") {
    return "Opened";
  } else if (typeOfStats == "clicked") {
    return "Clicked";
  } else if (typeOfStats == "replied") {
    return "Replied";
  } else if (typeOfStats == "notDelivered") {
    return "Not Delivered";
  }
}

export const isOpenChat = (conversationId) => {
  const conversationMap = getValueFromNestedObject(
    store.getState(),
    "ConversationInfoReducer.conversationObject.conversationMap"
  );
  return (
    conversationMap[conversationId] &&
    conversationMap[conversationId].chatStatus === CHAT_STATUS.Open
  );
}

export const getCursor = (cursorValue) => {
  for (let key in CURSOR_STATUS){
    if(CURSOR_STATUS[key] === cursorValue) return null;
  }
  return cursorValue;
};

export const getCreatedBy = (conversation) => {
  const { isOverflowChat, assignedTo } = conversation;
  let userId = getValueFromNestedObject(
    store.getState(),
    `UserReducer.data.id`
  );
  userId = userId ? userId : null;
  return isOverflowChat ? assignedTo : userId;
};

export const constructPayloadForAddTag = (conversation, tagObject) => {
  const { messageId, tagNameList } = tagObject;
  const { isOverflowChat } = conversation;
  const createdBy = getCreatedBy(conversation);
  return [tagNameList, conversation, createdBy, messageId, isOverflowChat];
};

export const isConversationExisted = (conversationId) => {
  const {
    ConversationInfoReducer: {
      conversationObject: { conversationMap },
    },
  } = store.getState();
  for (const key in conversationMap) {
    if (key === conversationId) {
      return true;
    }
  }
  return false;
};

export const isNumber = (value) => {
  return typeof value === 'number';
}

export const sortObjectArrayInAscendingOrder = (objectArray, prop) => {
  objectArray.sort(function (firstObj, secondObj) {
    var firstTagName = isNumber(firstObj[prop]) ? firstObj[prop] : firstObj[prop].toUpperCase();
    var secondTagName = isNumber(secondObj[prop]) ? secondObj[prop] : secondObj[prop].toUpperCase();
    if( firstTagName < secondTagName)  return -1
    return firstTagName > secondTagName ? 1 : 0;
  });
  return objectArray;
}
export const resetConversationMessagesIfNotOpenChat = (conversationId) => {
  if (!isOpenChat(conversationId))
    store.dispatch(MessageActions.resetConversationMessages(conversationId));
};

export const truncate = (text, maxlength) => {
  return text.length > maxlength ? text.substring(0, maxlength) + TRUNCATED_STRING_POSTFIX : text;
}

export const getCurrentVisitorPage = (currentUrl) => {
  let visitorPage = currentUrl.split("/");
  for (let index in visitorPage) {
    if (visitorPage[index].includes("?"))
      return visitorPage[index - 1];
  }
}
export const appendScript = (scriptPath) => new Promise((resolve, reject) => {
  const script = document.createElement('link');
  script.rel = "stylesheet"
  script.href = scriptPath;
  document.body.appendChild(script);
  script.onload = resolve;
  script.onerror = reject;
  return script;
});

export const getProjectIdsFromList = (projectList) => {
  return projectList.map((project) => project.key);
}

export const sortObjectArrayInDescendingOrder = (objectArray, prop) => {
  return sortObjectArrayInAscendingOrder(objectArray, prop).reverse();
};

export const isTagSearch = (chatStatus) => {
  return chatStatus === ADVANCED_SEARCH.TAG;
};

export const isNameSearch = (chatStatus) => {
  return chatStatus === ADVANCED_SEARCH.CUSTOMER;
};

export const isCompanySearch = (chatStatus) => {
  return chatStatus === ADVANCED_SEARCH.COMPANY;
};

export const isAdvancedFilterSearch = (chatStatus) => {
  return (
    isCompanySearch(chatStatus) ||
    isNameSearch(chatStatus) ||
    isTagSearch(chatStatus)
  );
};

export const requestConversationListForAdvancedSearch = (
  chatStatus,
  searchText,
  searchTextCursor,
  isNewList
) => {
  store.dispatch(
    conversationListRequest({
      projectId: getActiveProjectId(),
      chatStatus,
      searchText,
      searchTextCursor,
      isNewList,
    })
  );
};

export const getRecentSearchHistoryFromLocalStorage = () => {
  let localStorageResponse = localStorage.getItem(
    LOCAL_STORAGE_KEY + getProjectKey(getActiveProjectId())
  );
  if (localStorageResponse) return JSON.parse(localStorageResponse);
  return {};
};

export const getRecentSearchTextBySearchType = (searchType) => {
  const recentSearchHistory = getRecentSearchHistoryFromLocalStorage();
  return recentSearchHistory[searchType] ? recentSearchHistory[searchType] : "";
};

export const setSearchTextAndStoreItInLocalStorage = (
  searchText,
  searchType
) => {
  const recentSearchHistory = getRecentSearchHistoryFromLocalStorage();
  recentSearchHistory[searchType] = searchText;
  localStorage.setItem(
    LOCAL_STORAGE_KEY + getProjectKey(getActiveProjectId()),
    JSON.stringify(recentSearchHistory)
  );
};

export const setSearchTextFromLocalStorageIfEmptySearchText = (
  chatStatus,
  location
) => {
  let searchText = "";
  if (isTagSearch(chatStatus))
    searchText = getRecentSearchTextBySearchType(TAG_NAME_SEARCH);
  if (isNameSearch(chatStatus))
    searchText = getRecentSearchTextBySearchType(CUSTOMER_NAME_SEARCH);
  if (isCompanySearch(chatStatus))
    searchText = getRecentSearchTextBySearchType(COMPANY_NAME_SEARCH);
  location.state = {
    searchTextFilter: searchText,
    nonEncryptedSearchTextFilter: searchText,
  };
};


export const removeRecentComponentFromAW = ( conversationId ) => {
  ExternalBaseApis.removeItemInConnectRecent(conversationId);
}

export const sendAWDesktopNotifications = ( message , conversationId ) => {
  const payload = {
    "body": message,
    "title": "ChatSupport",
    "icon": "https://assets.chatsupport.co/website/stag/images/chatsupport-circle-logo.png",
    "chatType": "user",
    "origin": "https://staging.chatsupport.co",
    "sourceId": conversationId,
    "source": "chatsupport"
  }

  if(window.FrontOfficeService){
    window.FrontOfficeService.Notification.showDesktopNotification(payload);
  }
}

export const getStore = () => {
  return store;
}
export const getDomainBasedOnBrand = () => {
  let domain = window.document.domain;
  if(!domain)
    return;
  return getAppMode() === 'live' ? getProdFullAuthDomain(domain) : getStagFullAuthDomain(domain);
}

export const getProdFullAuthDomain = (domain) => {
  if(domain.includes('wellreceived'))
    return 'https://auth.wellreceived.app';
  else if(domain.includes('hellosells'))
    return 'https://auth.hellosells.app';
  else
    return 'https://auth.app.chatsupport.co';
}

export const getStagFullAuthDomain = (domain) => {
  if(domain.includes('wellreceived'))
    return 'https://auth.staging.wellreceived.app';
  else if(domain.includes('hellosells'))
    return 'https://auth.staging.hellosells.app';
  else
    return 'https://auth.staging.chatsupport.co';
}

export const sendErrorMail = (error) => {
  store.dispatch(mailGlobalError(error));
};

export const getConversationLimitByContainerHeight = () => {
  let containerElement = document.getElementsByClassName(
    CONVERSATION_LIST_CONTAINER_CLASSNAME
  );
  const CONVERSATION_LIST_ITEM_HEIGHT_IN_PX = 101;
  const fallbackConversationLimit = 10;
  if (containerElement && containerElement[0]) {
    let { offsetHeight } = containerElement[0];
    let calculateLimit = offsetHeight
      ? Math.round(offsetHeight / CONVERSATION_LIST_ITEM_HEIGHT_IN_PX)
      : fallbackConversationLimit;
    return calculateLimit * 2;
  }
  return fallbackConversationLimit * 2;
};

export const hasReachedBottomOfTheContainer = (userScorlledHeight, totalScrollableHeight) => {
  let _75PercentOfScrollHeight = totalScrollableHeight * 0.7;
  return userScorlledHeight >= _75PercentOfScrollHeight;
};

export const desktopNotifyPayloadTrigger = (messageBody, messageFrom, data, messageId) => {
  if (messageBody && messageBody.length && messageBody.length === 0) {
    messageBody = "New Incoming Conversation";
  }
  let notifyPayload = constructDesktopNotifyPayload(messageBody, messageFrom, data);
  console.log("This is payload notify final ", JSON.stringify(notifyPayload));
  console.log("This is message body: ", messageBody);
  store.dispatch(updateLastNotifyMessageId(messageId));
  ExternalBaseApis.showClientNotification(notifyPayload);
}

export const constructDesktopNotifyPayload = (messageBody, messageFrom, data) => {
  console.log("Got this for testing convo", JSON.stringify(data));
  return {
      "body": getNotifyMessageBody(messageBody,data),
      "title": messageFrom,
      "icon": "https://assets.chatsupport.co/webapp/images/chatsupport-connect.svg",
      "chatType": "user",
      "origin": "https://live-fullspectrum.appspot.com",
      "sourceId": data.conversationId,
    "source": "connect",
    onClick: () => {
      let isAnsweringEnabled = store.getState().ChatAnsweringReducer.answeringRing[data.conversationId];
      if (!ExternalBaseApis.hasItemInFrontOffice(data.conversationId) && !isAnsweringEnabled) {
        ExternalBaseApis.addItemToConnectRecent({ itemId: data.conversationId }, "0");
      }
      if (!isAnsweringEnabled) {
        ExternalBaseApis.navigateToRecentItem(data.conversationId);
      }
  }
  }
}

export const getNotifyMessageBody = (messageBody, data) => {
  if (data.type != "ATTACHMENT" && data.type != "CS_PRECHATSURVEY") {
    return messageBody;
  } else if (data.type == "CS_PRECHATSURVEY") {
    return "New Incoming Conversation";
  } else {
    let files = messageBody;
    let filesArr = files.split(",");
    let fileName = filesArr[4].split(":")[1];
    fileName = fileName.replaceAll('"', ' ');
    fileName = fileName.replaceAll("/", ' ');
    console.log("This is final value: ", fileName);
    fileName = "Shared a file: " + fileName;
    return fileName;
  }
}

export const isAdminUserObj = (userObj) =>
  userObj && !userObj.originalId && userObj.defaultProject;

export const isCurrentFilterClicked = (pathName) => {
  const currentPathNameArray = location.pathname.split('/');
  const pathNameArray = pathName.split("/");
  return pathNameArray[8] === currentPathNameArray[8] && pathNameArray[6] === currentPathNameArray[6];
}
export const getPathName = (filterName, projectKey, filterType) => {
      return `/app/${projectKey}/chat/dashboard/D/${CHAT_FILTERS_CHAT_STATUS[filterName]}/N/${filterType}/`;
}
export const checkAndSetLoopForAudio = (audioElement) => {
  if (typeof audioElement.loop === "boolean") {
    audioElement.loop = true;
  } else {
    audioElement.addEventListener("ended", function () {
      this.currentTime = 0;
      this.play();
    });
  }
  return audioElement;
};

export const getChatAnsweringPopupAudio = () => {
  if (window.Audio) {
    let audioElement = new Audio(CHAT_ANSWERING_POPUP_NOTIFICATION_SOUND);
    return checkAndSetLoopForAudio(audioElement);
  }
};

export const isValidResponse = (response) =>
response && response.data && response.response && response.status !== 500;
export const getKeyLength = (obj) => {
  if (typeof obj === "object") return Object.keys(obj).length;
  return 0;
};

export const areNewMessagesAdded = (prevMessageMap, currentMessageMap) => {
  return (
    prevMessageMap &&
    currentMessageMap &&
    getKeyLength(prevMessageMap) < getKeyLength(currentMessageMap)
  );
};

export const scrollElementToBottom = (element) => {
  element.scrollTop = element.scrollHeight;
}

export const isInternalUser = () => {
  const userReducer = store.getState().UserReducer;
  return userReducer ? userReducer.isInternalUser : false;
}

export const getUserReducerFromProps = (UserReducer) => {
  return UserReducer.data ? UserReducer.data.id : "";
}

export const getStaffMap = (projectKey) => {
  return store.getState().StaffReducer.dashboardAgents ? store.getState().StaffReducer.dashboardAgents[projectKey] : {};
}
export const isValidStaffMap = (staffMap) =>
  staffMap && staffMap.agents && staffMap.staffs && !staffMap.exception;

export const isFullScreenMode = () => {
  return store.getState().UserReducer.isFullScreenMode ? store.getState().UserReducer.isFullScreenMode : false;
}

export const getDOMRootElement = () =>
  document.getElementById("root") || document.getElementById("app");

export const getChatAssignedTypeByComponent = () =>
  getIsAWRecentComponent()
    ? MESSAGE_TYPE.chat_assigned_on_connect
    : MESSAGE_TYPE.chat_assigned;

export const isInternalServerError = error =>
  error && error.status && error.status > 499;
  
export const isActiveElement = (element) => document.activeElement === element;

export const isChatClosedAsPendingByMessageType = (messageType) =>
  messageType === MESSAGE_TYPE.chat_closed_as_pending;

export const getRespectiveStyleforAWChat = () => {
  return {
    backgroundColor: CONNECT_USER_AVATAR_COLOR.INACTIVE,
    display: "flex",
    "background-image": "none",
    "align-items": "center",
    "justify-content": "center"
  };
}

export const validateAndUpdateContactPresenceForConnectAndVisitors = (
  request,
  data
) => {
  return (
    (getIsAWRecentComponent() && request.userIds[0].startsWith("aw/")) ||
    (data && data[0] && data[0].userInfo.messageFrom === MESSAGE_FROM.VISITOR) ||
    !getIsAWRecentComponent()
  );
}

export const sortMessageMapByProperty = (
  object,
  property,
  isAscendingOrder
) => {
  let objectArray = Object.values(object);
  let sortedObjectArray = isAscendingOrder
    ? sortObjectArrayInAscendingOrder(objectArray, property)
    : sortObjectArrayInDescendingOrder(objectArray, property);
  return sortedObjectArray.reduce((newObject, obj) => {
    newObject[obj.key] = obj;
    return newObject;
  }, {});
};
export const getWorkingHoursUrl = (projectKey) =>
  `/app/${projectKey}/settings/myprofile/workinghours`;

export const revalueFilteredPeopleData = (listing) => {
  if ((listing.emailSubscription) === EMAIL_SUBSCRIPTION_UUID) {
    listing.emailSubscription = filterTypes.subscribed;
  } else if ((listing.emailSubscription) === EMAIL_UNSUBSCRIPTION_UUID) {
    listing.emailSubscription = filterTypes.unsubscribed;
  }
  return listing;
}
export const getMyProfilePageUrl = (projectKey) =>
  `/app/${projectKey}/settings/myprofile`;

export const getManageWidgetsPageUrl = (projectKey) =>
  `/app/${projectKey}/settings/manage/widget`;

export const getNotificationsPageUrl = (projectKey) =>
  `/app/${projectKey}/settings/notifications`;

export const getSettingsBillingPageUrl = (projectKey) =>
  `/app/${projectKey}/settings/billing`;

export const isUserRoleExist = (userDetails) => {
  return (
    userDetails &&
    userDetails.data &&
    userDetails.oldUserPermission &&
    userDetails.userRole
  );
}
export const validateConnectAndChatconsolePresenceUpdate = ({userId}) => {
  if (getIsAWRecentComponent())
    return userId.startsWith("aw/");
  else
    return !userId.startsWith("aw/");
}

export const getValidPeoplesEntity = (guestInfo) =>{
  return {
    ...guestInfo,
    name: getFullName(guestInfo) || "",
    currentUrl: guestInfo.currentPage,
    projectId: guestInfo.projectName,
    totalVisit: guestInfo.totalVisit || 1,
    isDeleted: false,
    isOnline: true
  }
}
export const getTeamMembersList = () => {
  const staffReducer = store.getState().StaffReducer;
  const userReducer = store.getState().UserReducer;
  const projectId = getActiveProjectId();
  let accountStaffsMap = {};
  if (
    !!staffReducer &&
    staffReducer.hasOwnProperty("accountStaffs") &&
    staffReducer.accountStaffs &&
    !userReducer.isInternalUser
  ) {
    accountStaffsMap = staffReducer.accountStaffs;
  }
  if (
    userReducer.isInternalUser &&
    staffReducer.hasOwnProperty("dashboardAgents") &&
    staffReducer.dashboardAgents.hasOwnProperty(projectId)
  ) {
    accountStaffsMap = staffReducer.dashboardAgents[projectId];
  }
  return accountStaffsMap;
};

export const updateTeamMembersList = () => {
  const teamMembers = getTeamMembersList();
  const key = Object.keys(teamMembers)[0];
  store.dispatch(getStaffContact(key));
};

export const isTeamMembersPage = () => {
  return location.pathname.includes("directory/myteam");
};
export const isOpenChatPage = () => {
  return isMineActiveChatPage() || isGuestPage() || isQueuePage();
};
export const getConversationClass = (state) => {
  let classAtbottom = state.atBottom
    ? "cs-chat-list scroll scroll-top"
    : "cs-chat-list scroll";
  let conversationClass = state.atTop
    ? "cs-chat-list scroll scroll-top "
    : classAtbottom;
  conversationClass =
    conversationClass +
    (getUserPermissionUtility("OnlineStatusSwitch") ? "" : " full-scroll");
  return conversationClass;
};

export const getEmptyConversation = (conversationReducer) => {
  let conversationMapFromProps = conversationReducer
    ? conversationReducer.conversationObject.conversationMap
    : undefined;
  return conversationMapFromProps && conversationReducer
    ? Object.keys(conversationMapFromProps).length === 0 &&
        !document.location.pathname.includes("guests") &&
        !document.location.pathname.includes("queue") &&
        !conversationReducer.isSingleFetching &&
        !conversationReducer.isFetching &&
        (conversationReducer.isFetched || conversationReducer.isSingleFetched)
    : undefined;
};
export const getModifiedDateFromConversationObject = (conversationObject) => {
  return conversationObject && conversationObject.modifiedDate
    ? conversationObject.modifiedDate
    : "";
};
export const getUnReadCountMapFromResponse = (response) => {
  return response.hasOwnProperty("unReadCount") ? response["unReadCount"] : {};
};
export const getNewMessageMapFromResponse = (response) => {
  return response.hasOwnProperty("newMessage") ? response["newMessage"] : {};
};
export const getConversationLimitFromConversationObject = (
  conversationObject
) => {
  return conversationObject && conversationObject.limit
    ? conversationObject.limit
    : "";
};
export const getMessageMapFromResponse = (response) => {
  let messageMap = {};
  response.messages.map((item, i) => {
    if (item && item.type !== MESSAGE_TYPE.cs_prechatsurvey)
      messageMap[item.key] = item;
  });
  return messageMap;
};
export const getContactsMapFromResponse = (response) => {
  let contactsMap = {};
  let contactKeys = [];
  response.visitors.map((item, i) => {
    contactsMap[item.key] = item;
    contactKeys.push(item.key);
  });
  return { contactsMap: contactsMap, contactKeys: contactKeys };
};
export const getProjectIdFromConversationObject = (conversationObject) => {
  return conversationObject && conversationObject.projectId
    ? conversationObject.projectId
    : "";
};
export const getSearchTextFromConversationObject = (conversationObject) => {
  return conversationObject && conversationObject.searchText
    ? conversationObject.searchText
    : "";
};
export const getConversationsInfoObject = (conversationMap, contactsMap) => {
  return {
    conversationMap: conversationMap,
    isConversationMapEmpty:
      Object.keys(conversationMap).length === 0 ? true : false,
    contactsMap: contactsMap,
  };
};
export const getQueuedConversationsFromResponseObj = (url, responseObj) => {
  let queuedConversations = [];
  if (url === "/conversation/queue/get") {
    for (let conversationId in responseObj.conversationsInfo.conversationMap)
      queuedConversations.push(conversationId);
  }
  return queuedConversations;
};

export const getLastMessageFromMessageMap = (lastMessageId, messageMap) => {
  return lastMessageId && messageMap.hasOwnProperty(lastMessageId)
    ? messageMap[lastMessageId].message
    : "";
};
export const getUnReadCountFromUnReadCountMap = (unReadCountMap, key) => {
  return unReadCountMap.hasOwnProperty(key) ? unReadCountMap[key] : 0;
};
export const getNewMessageFromNewMessageMap = (newMessageMap, key) => {
  return newMessageMap.hasOwnProperty(key) ? newMessageMap[key] : "";
};

export const getMessageFromInMessageMap = (lastMessageId, messageMap) => {
  return lastMessageId && messageMap.hasOwnProperty(lastMessageId)
    ? messageMap[lastMessageId].messageFrom
    : "AGENT";
};

export const getInteractionTypeFromMessageMap = (lastMessageId, messageMap) => {
  return lastMessageId && messageMap.hasOwnProperty(lastMessageId)
    ? messageMap[lastMessageId].interactionType
    : "DASH_BOARD";
};

export const getLastMessageSenderIdFromMessageMap = (
  lastMessageId,
  messageMap
) => {
  return lastMessageId && messageMap.hasOwnProperty(lastMessageId)
    ? messageMap[lastMessageId].senderId
    : "";
};

export const getModifiedDateCursor = (oldModifiedDateCursor, modifiedDateCursor) => {
  return (oldModifiedDateCursor && oldModifiedDateCursor == modifiedDateCursor) || modifiedDateCursor == ""
      ? "NO_CURSOR"
      : modifiedDateCursor;
}

export const constructConversationInReducer = (
  url,
  response,
  conversationRequest
) => {
  response = response.data;
  let responseObj = {};
  let conversationMap = {};
  let messageMap = {};
  let modifiedDateCursor = "";
  let oldModifiedDateCursor = getModifiedDateFromConversationObject(
    conversationRequest.conversationObject
  );
  let unReadCountMap = getUnReadCountMapFromResponse(response);
  let newMessageMap = getNewMessageMapFromResponse(response);
  let limit = getConversationLimitFromConversationObject(
    conversationRequest.conversationObject
  );
  messageMap = getMessageMapFromResponse(response);

  response.conversations.map((item, i) => {
    let lastMessageId = item.lastMessageId ? item.lastMessageId : "";
    let messageObj = "";

    if (
      !!lastMessageId &&
      messageMap.hasOwnProperty(lastMessageId) &&
      messageMap[lastMessageId].type == "ATTACHMENT"
    ) {
      try {
        messageObj = JSON.parse(messageMap[lastMessageId].message);
        item["lastMessage"] = messageObj.fileName;
      } catch (err) {
        item["lastMessage"] = stripHTML(
          getLastMessageFromMessageMap(lastMessageId, messageMap)
        );
      }
    } else
      item["lastMessage"] = stripHTML(
        getLastMessageFromMessageMap(lastMessageId, messageMap)
      );
    item["unReadCount"] = getUnReadCountFromUnReadCountMap(
      unReadCountMap,
      item.key
    );
    item["newMessage"] = getNewMessageFromNewMessageMap(
      newMessageMap,
      item.key
    );
    item["messageFrom"] = getMessageFromInMessageMap(lastMessageId, messageMap);
    item["interactionType"] = getInteractionTypeFromMessageMap(
      lastMessageId,
      messageMap
    );
    item["lastMessageSenderId"] = getLastMessageSenderIdFromMessageMap(
      lastMessageId,
      messageMap
    );
    let closedMentionChats = [];
    if (item.mentionedStaffs)
      item.mentionedStaffs.forEach((agentId, index) => {
        if (
          agentId.includes("_CLOSED") &&
          document.location.href.includes(agentId.replace("_CLOSED", ""))
        ) {
          delete item.mentionedStaffs[index];
          closedMentionChats.push(agentId.replace("_CLOSED", ""));
        } else item.mentionedStaffs[index] = agentId.replace("_CLOSED", "");
      });
    item.closedMentionChats = closedMentionChats;
    conversationMap[item.key] = item;
    modifiedDateCursor = item.modifiedDate;
  });
  let { contactsMap, contactKeys } = getContactsMapFromResponse(response);
  responseObj.conversationsInfo = getConversationsInfoObject(
    conversationMap,
    contactsMap
  );
  responseObj.projectId = getProjectIdFromConversationObject(
    conversationRequest.conversationObject
  );
  responseObj.modifiedDateCursor = getModifiedDateCursor(oldModifiedDateCursor, modifiedDateCursor);
  responseObj.cursor = response.cursor;
  responseObj.isMaxLimitReached =
    limit && limit > Object.keys(conversationMap).length;
  responseObj.searchText = getSearchTextFromConversationObject(
    conversationRequest.conversationObject
  );
  responseObj.queuedConversations =
    getQueuedConversationsFromResponseObj(url, responseObj);
  return { responseObj, contactKeys };
};

export const shouldShowConversationList = (props, conversationMap) => {
  const { ConversationQueueReducer, VisitorQueueReducer } = props.dashboardData;
  return (
    (ConversationQueueReducer.isFetched &&
      VisitorQueueReducer.showConversationList &&
      VisitorQueueReducer.isInitialFetchComplete &&
      getUserPermissionUtility("OnlineStatusSwitch") &&
      isOpenChatPage()) ||
    (!isOpenChatPage() && Object.keys(conversationMap).length > 0)
  );
};
export const playNotifierAudio = () => {
  if (AUDIO_OBJECT) AUDIO_OBJECT.play();
};
export const getUserProjectMapping = () => {
  return store.getState().UserProjectMappingReducer.userProjectMapping;
}

export const isConnectAuthAllowed = () => {
  let connectAuthId = store.getState().UserReducer ? store.getState().UserReducer.connectAuthId : undefined;
  return connectAuthId === CONNECT_AUTH.ALLOWED ? "block" : "none";
}

export const getUserIdFromState = () => {
  return store.getState().UserReducer.data ? store.getState().UserReducer.data.id : "";
}

export const getProjectReducer = () => {
  return store.getState().ProjectReducer;
}
export const shouldSwitchStatusToAvailable = (props, staff, hasStatusSwitchedToAvailableOnAppLoad) => {
  let {
    user: { isInternalUser: isInternalUsr, data: { isAdmin } = {} },
    chatConfiguration: { data = {} },
    messageReducer: { isSubscribedToChannels },
  } = props;
  let { key, takingChatStatus } = staff;
  return (
    key &&
    takingChatStatus !== AVAILABILITY_STATUS.AVAILABLE &&
    !hasStatusSwitchedToAvailableOnAppLoad &&
    isSubscribedToChannels &&
    !(
      isInternalUsr ||
      hasOverflowEnabledProjects(data) ||
      isAdmin === "true"
    )
  );
}

export const isAssigneeIdExistInChatDashboardRoute = () => {
  const path = window.location.pathname;
  return (
    path.substring(path.length - 15, path.length) === "/chat/dashboard"
  );
};

export const checkAndStorePlanIdWithKeyOfRespectiveUserId = (userId, planId) => {
  if (userId && planId) {
    let key = userId + "_" + PLAN_ID;
    localStorage.setItem(key, parseInt(planId));
    localStorage.removeItem(PLAN_ID);
  }
}

export const checkAndStoreValueInLocalStorage = (key, value) => {
  if (key && value) localStorage.setItem(key, value);
};

export const getPlanIdFromStateObj = (stateObj) => {
  return stateObj.planId && stateObj.planId.toString().trim()
    ? stateObj.planId
    : "";
};
export const constructMessageStoreObject = (messageData) => {
  let {
    timestamp,
    messageFrom,
    visitorId,
    senderId,
    mentionedStaffs,
    type,
    messageId,
  } = messageData;
  return {
    ...messageData,
    createdDate: timestamp,
    senderId: messageFrom === MESSAGE_FROM.VISITOR ? visitorId : senderId,
    messageFrom: messageFrom || MESSAGE_FROM.VISITOR,
    key: messageId,
    mentionedStaffs: mentionedStaffs ? mentionedStaffs : [],
    type: type || MESSAGE_TYPE_LOWERCASE.chat,
  };
};

export const addEventListener = (...args) => {
  window.addEventListener(...args);
}

export const showVoiceboxIfForbiddenRequest = (error) => {
  const { response: { status = 0 } = {} } = error;
  if (status === 403)
    store.dispatch(
      showVoicebox({
        showError: true,
        dismissAfter: 3000,
        message: "Access Denied",
      })
    );
};

export const getLogoutUrl = () => {
  return getAppMode() === "live" ? LIVE_LOGOUT_URL : STAGING_LOGOUT_URL;
};

export const redirectToLoginPage = () => {
  cookie.remove(CLIENT_LOGIN_REFERENCE, { path: "/" });
  window.location.href = getLogoutUrl() + getOrigin() + "/login";
};

export const getUrlPathname = () => {
  return document.location.pathname;
}

export const isClickedInside = (elementId, event) => {
  const element = document.getElementById(elementId);
  if (element) {
    const position = element.getBoundingClientRect();
    return (
      position.top < event.clientY &&
      event.clientY < position.bottom &&
      position.left < event.clientX &&
      event.clientX < position.right
    );
  } else {
    return false;
  }
};

export const detailsTabClassName = (isDetailSection) => {
  return isDetailSection
    ? "active"
    : "";
};

export const historyTabClassName = (
  visitorOption,
  isDetailSection,
  visitorConversationMap
) => {
  let className = "";
  if (visitorOption == "H" || !isDetailSection) {
    className += "active";
  }
  if (visitorConversationMap && Object.keys(visitorConversationMap).length < 1) {
    className += " dn";
  }
  return className;
};

export const visitorDetailsClassName = (props) => {
  let {classString, isOverflowConversation, isAwWindow, displayVisitorDetails} = props;
  let className = "cs-user-info";
  if (classString) {
    className += classString;
  }
  if (isOverflowConversation || isAwWindow) {
    if (displayVisitorDetails) {
      className += " db";
    } else {
      className += " dn";
    }
  }
  return className;
};

export const visitorDetailsStyle = (props) => {
  if(props?.isOverflowConversation)
    return { height: "100vh", background: "#ffffff", zIndex: "2" }
  else
    return {};
}

export const constructPathnameInVisitorDetails = (
  isOverflowConversation,
  projectkey,
  visitorOptions,
  chatStatus,
  sortBy,
  assignedTo,
  conversationId = ""
) => {
  if (isOverflowConversation) {
    return "/overflow/" + visitorOptions + location.search;
  }
  if (conversationId) {
    return (
      "/app/" +
      projectkey +
      "/chat/dashboard/" +
      visitorOptions +
      "/" +
      chatStatus +
      "/" +
      sortBy +
      "/" +
      assignedTo +
      "/" +
      conversationId +
      location.search
    );
  } else {
    return (
      "/app/" +
      projectkey +
      "/chat/dashboard/" +
      visitorOptions +
      "/" +
      chatStatus +
      "/" +
      sortBy +
      "/" +
      assignedTo +
      "/" +
      location.search
    );
  }
};


export const isCampaignDesignComplete = (campaign) => {
  const { campaignName, message, senderId, subject, typeOfMessage } = campaign;
  if (typeOfMessage == campaignTypes.email) {
    return campaignName &&
    senderId &&
    subject &&
    !message.includes(DEFAULT_CAMPAIGN_CONTENT);
  }
  return campaignName && senderId && !message.includes(DEFAULT_CAMPAIGN_CONTENT);
};
export const shouldSendDeliveredStatus = (
  messageData,
  userId
) => {
  let { type, messageFrom, messageStatus } = messageData;
  return (
    userId &&
    READABLE_MESSAGE_TYPES.includes(type) &&
    messageFrom === "VISITOR" &&
    !messageStatus
  );
};
